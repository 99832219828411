.photo-grid {
    position: relative;

    --gap: 1rem;

    @include media-breakpoint-up(sm) {
        --gap: 2rem;
    }

    & h2 {
        margin-bottom: 0;
        text-transform: lowercase;

        & + div {
            margin-top: calc(-1 * var(--font-size-h2) * 0.16);
        }
    }

    & > div {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        gap: var(--gap);
    }

    & picture {
        display: block;
        flex-basis: 100%;
        background-color: var(--labd-theme-color);
    }

    & img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    @include media-breakpoint-up(sm) {
        & .full-width {
            flex-basis: 100%;
            aspect-ratio: 16 / 9;
        }

        & .half-width {
            flex-basis: calc(50% - 0.5 * var(--gap));
            aspect-ratio: 16 / 9;
        }

        & .two-third {
            flex-basis: calc(66.66% - 0.5 * var(--gap));
            aspect-ratio: 16 / 9;
        }

        & .one-third {
            flex-basis: calc(33.33% - 0.5 * var(--gap));
        }
    }
}
