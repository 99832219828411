.pattern-image {
    &__pattern {
        position: absolute;
        z-index: 10;
        top: 0;
        width: 50%;
        height: auto;
        transform: translate(-20%, -20%);
        stroke: var(--labd-theme-color);

        [class^="col-"] + [class^="col-"] & {
            right: 0;
            left: auto;
            transform: translate(20%, -20%);
        }
    }
}
