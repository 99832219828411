.hubspot-form {
    display: flex;
    min-height: 10rem;
    scroll-padding-block-start: 1.5rem;
    place-items: center;

    > iframe {
        overflow: visible !important;
        min-height: inherit !important;
    }
}
