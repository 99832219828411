.statement {
    position: relative;
    padding-top: 80px;
    padding-bottom: 80px;

    &__text {
        .rich-text {
            max-width: unset;
        }

        p {
            @extend .h3;

            font-size: clamp(1.75rem, 4.5vw, 3rem);
            font-weight: $font-weight-normal;
            line-height: 1.5;
        }

        @include media-breakpoint-up(xl) {
            b,
            strong {
                position: relative;
                display: inline-block;

                &::after {
                    content: "";
                    position: absolute;
                    bottom: 5px;
                    left: 0;
                    width: 100%;
                    height: clamp(5px, 1vw, 20px);
                    background-color: rgba($black, 0.1);
                }
            }
        }
    }

    &.theme-background::before {
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        left: 50%;
        width: 100vw;
        height: 100%;
        margin-left: -50vw;
        background-color: inherit;
    }

    &__actions {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 24px;

        & > :not(:last-child) {
            margin-bottom: 1rem;
        }
    }

    @include media-breakpoint-up(sm) {
        &__actions {
            flex-direction: row;

            & > :not(:last-child) {
                margin-right: 1rem;
                margin-bottom: 0;
            }
        }
    }

    @include media-breakpoint-up(xxl) {
        padding-top: 160px;
        padding-bottom: 160px;
    }

    @include media-breakpoint-up(lg) {
        &__actions {
            margin-top: 48px;
        }
    }
}
