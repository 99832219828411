.carousel {
    position: relative;
    background-color: var(--labd-theme-color);

    --padding: 1.25rem;
    @include media-breakpoint-up(md) {
        --padding: 2.5rem;
    }
    @include media-breakpoint-up(lg) {
        --padding: 5rem;
    }

    & > h2 {
        @extend .h5;

        padding: var(--padding);

        @include media-breakpoint-up(md) {
            position: absolute;
            top: var(--padding);
            left: var(--padding);
            padding: 0;
        }
    }

    &-slides {
        @include media-breakpoint-up(md) {
            width: 100%;
        }
        @include media-breakpoint-up(lg) {
            display: grid;
            grid: [stack] 1fr / [stack] 1fr;
        }

        & .carousel-slide:not(:first-of-type) {
            display: none;
            @include media-breakpoint-up(lg) {
                display: flex;
            }
        }

        & .carousel-slide {
            @include media-breakpoint-up(lg) {
                grid-area: stack;
                opacity: 0;
                flex-direction: row;
            }
        }
    }

    &-slide {
        display: flex;
        flex-direction: column;
        width: 100%;
        transition: opacity 0.3s ease;

        @include media-breakpoint-up(lg) {
            visibility: hidden;
        }

        & > div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: var(--padding);

            --icon-color: black;

            @include media-breakpoint-up(md) {
                padding-top: 5rem;
            }
            @include media-breakpoint-up(lg) {
                flex-basis: 50%;
                padding: 10rem var(--padding);
            }

            .quote {
                padding: 2rem 0;
            }

            .quote__inner {
                flex-direction: column;
                gap: 1rem;
            }
        }

        & > picture {
            position: relative;
            display: block;
            overflow: hidden;

            & > .background {
                position: absolute;
                // background is a bit bigger than the content box to compensate for the border blur
                top: -2rem;
                left: -2rem;
                width: calc(100% + 4rem);
                height: calc(100% + 4rem);
                background-position: center center;
                background-size: 300%;
                background-repeat: no-repeat;
                filter: blur(2rem) brightness(50%);
            }

            @include media-breakpoint-up(lg) {
                flex-basis: 50%;
                height: auto;
                margin: 0;
            }

            img {
                position: relative;
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        &.selected {
            opacity: 1;
            visibility: visible;
            position: relative;
            z-index: 1;
        }
    }

    &-indicators {
        position: absolute;
        z-index: 2;
        bottom: var(--padding);
        left: var(--padding);
        display: none;
        gap: 1rem;

        @include media-breakpoint-up(lg) {
            display: flex;
            cursor: default;
        }

        & button {
            position: relative;
            display: block;
            width: 2rem;
            height: 2rem;
            padding: 0;
            border: none;
            background-color: transparent;
            color: black;
            font-size: 1rem;
            font-weight: bold;
            line-height: 2rem;
            opacity: 0.3;

            &:hover {
                opacity: 1;
            }

            &.selected {
                opacity: 1;

                &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    border: 2px solid black;
                    transform: rotate(45deg);
                    transform-origin: center center;
                }
            }
        }
    }

    &-dialog-button {
        position: absolute;
        z-index: 2;
        right: 0;
        bottom: 0;
        display: flex;
        width: 4rem;
        height: 4rem;
        border: none;
        margin: var(--padding);
        background-color: var(--labd-theme-color);
        background-image: url("/static/labdigital/svgs/icon-arrow-right.svg");
        background-position: center center;
        background-repeat: no-repeat;
        border-radius: 4rem;

        @include media-breakpoint-up(lg) {
            display: none;
        }
    }

    @include media-breakpoint-up(lg) {
        &.image-left {
            .carousel-slide > div {
                order: 2;
            }

            & .carousel-indicators,
            h2 {
                left: calc(50% + var(--padding));
            }
        }
    }
}

.carousel-dialog {
    cursor: auto;

    > .full-screen-dialog-content {
        padding: var(--padding);
        margin: 0 auto;
    }

    h2 {
        @extend .h5;

        padding: var(--padding) 5rem var(--padding) 0;
        margin-bottom: 0;
    }

    .carousel-slide {
        visibility: visible;
    }

    .carousel-slide > div {
        padding-top: var(--padding);
        padding-right: 0;
        padding-left: 0;
    }

    .carousel-slide:first-of-type > div {
        padding-top: 0;
    }
}

@media (hover: hover) and (min-width: breakpoint-min(lg, $grid-breakpoints)) {
    .carousel {
        cursor: none;

        a,
        button {
            cursor: pointer;
        }
    }

    .carousel:hover .carousel-pointer {
        display: flex;
    }

    .carousel-pointer {
        position: fixed;
        z-index: 1000;
        display: none;
        justify-content: center;
        align-items: center;
        width: 10rem;
        height: 10rem;
        border-radius: 10rem;
        background-color: hsla(0deg 0% 0% / 10%);
        backdrop-filter: blur(1rem);
        transform: translate(-50%, -50%) rotate(var(--rotate, 0));
        pointer-events: none;

        &::after {
            content: "";
            display: block;
            width: 2.5rem;
            height: 2.5rem;
            margin-left: 0.5rem;
            background-color: white;
            mask: url("/static/labdigital/svgs/icon-chevron-right.svg") no-repeat center center;
            mask-size: 2.5rem 2.5rem;
        }
    }

    .carousel-indicators:hover ~ .carousel-pointer {
        display: none;
    }
}
