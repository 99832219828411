// Use this file to override all bootstrap default variables, all variables
// which can be overridden here are listed in this file:
// https://raw.githubusercontent.com/twbs/bootstrap/v5.1.3/scss/_variables.scss

// Color system
$white: #fff;
$gray-400: #ccc;
$gray-500: #c2c2c2;
$gray-800: #333;
$black: #000;

$green: #02ff5d;
$pink: #ff56cb;
$turquoise: #08e7e9;
$yellow: #ffd500;
$purple: #bb9dfc;

$primary: $black;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem;
$spacers: (
    0: 0,
    1: $spacer * 0.25,
    2: $spacer * 0.5,
    3: $spacer,
    4: $spacer * 1.5,
    5: $spacer * 3,
);

$container-padding-x: 1.5rem;

// Body
//
// Settings for the `<body>` element.
$body-color: $black;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1680px,
);

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

// scss-docs-start container-max-widths
$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1600px,
);
// scss-docs-end container-max-widths

// Typography
//
// Font, line-height, and color for body text, headings, and more.
$font-weight-normal: 400;
$font-weight-bold: 700;

$font-family-base: "TT Commons Pro Exp", "Helvetica Neue", arial, sans-serif;
$headings-font-family: "TT Commons Pro Exp", "Helvetica Neue", arial, sans-serif;

$headings-font-weight: $font-weight-bold;

$text-muted: $gray-500;

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.
$btn-border-width: 2px;
$btn-padding-y: 16px;
$btn-padding-x: 32px;
$btn-font-size: 1rem;
$btn-line-height: 20px;
$btn-font-weight: $font-weight-bold;
$btn-border-radius: 28px;

$grid-gutter-width: 1.5rem;
$gutters: (
    0: 0,
    1: $spacer * 0.25,
    2: $spacer * 0.5,
    3: $spacer,
    4: $spacer * 1.5,
    5: $spacer * 3,
    6: $spacer * 5.625,
);
