@use "../abstracts/functions/fluid-size" as *;

.page-campaign {
    background-color: var(--labd-theme-color);
    background-image: var(--landing-bg);
    background-size: contain;
    background-repeat: no-repeat;
    padding-block-start: #{fluid(160px, 480px)};

    > .landing-grid {
        display: grid;
        grid-template-columns:
            minmax(4vw, calc(50vw - 48.5rem))
            [contained-start] minmax(0, 1fr) [contained-end]
            minmax(4vw, calc(50vw - 48.5rem));

        @include media-breakpoint-up(lg) {
            padding-block-end: #{fluid(16px, 96px)};
        }

        > * {
            grid-column: 1 / -1;
            padding: #{fluid(16px, 96px)};

            @include media-breakpoint-up(lg) {
                grid-column: 2;
            }

            + * {
                padding-block-start: 0;
            }
        }

        > *:not(.image-text) {
            background-color: var(--bs-body-bg);
        }

        .container-xxl {
            padding: 0;
        }
    }
}
