.js-slide-image {
    --slide-duration: 600ms;
    --slide-ease: cubic-bezier(0.25, 0.46, 0.45, 0.84);
    --x: 0;
    --y: 0;

    img {
        --resistance: 10;
        --scale: calc(1 / var(--resistance) + 1);

        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform var(--slide-duration) var(--slide-ease);

        @media (hover: hover) {
            & {
                transform: scale(var(--scale))
                    translate(
                        calc(var(--x) / var(--resistance) * 1px),
                        calc(var(--y) / var(--resistance) * 1px)
                    );
            }
        }
    }
}
