.image-text {
    position: relative;
    overflow-x: hidden;

    &__image {
        position: relative;
    }

    &__title {
        margin-bottom: 1rem;
    }

    &__subtitle {
        margin-bottom: 0.5rem;
    }

    &__actions {
        margin-top: 1.5rem;
    }

    .block-image {
        width: 100%;
    }

    &__pattern {
        position: absolute;
        top: 0;
        left: -$grid-gutter-width;
        width: calc(100% + $grid-gutter-width * 2);
        height: 100%;
        object-fit: cover;

        &--top {
            z-index: 10;
        }
    }

    .block + .block {
        padding-top: 32px;
    }

    @include media-breakpoint-up(md) {
        .block + .block {
            padding-top: 48px;
        }
    }

    @include media-breakpoint-up(lg) {
        &__image {
            display: flex;
            align-items: center;
        }

        &__content {
            display: flex;
            align-items: center;
        }

        &__title {
            margin-bottom: 2rem;
        }

        &__subtitle {
            margin-bottom: 1rem;
        }

        &__actions {
            margin-top: 2.5rem;
        }

        &__pattern {
            left: -4rem;
            width: 115%;
        }

        .block + .block {
            padding-top: $block-spacing-desktop;
        }
    }
}
