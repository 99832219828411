body {
    &.is-scrolling-up,
    &.is-scrolled {
        --header-height-tablet: 80px;
        --header-height-desktop: 100px;
    }
}

template {
    display: none !important;
}
