.project-card {
    --padding: 1.5rem;
    --badge-padding: 0.25rem 0.5rem;
    --gap: 1rem;
    --time-font-size: 0.875rem;

    @include media-breakpoint-up(md) {
        --badge-padding: 0.25rem 0.75rem;
    }
    @include media-breakpoint-up(lg) {
        --padding: 2rem 2.5rem;
        --gap: 2rem;
        --badge-padding: 0.5rem 1rem;
        --time-font-size: 1rem;
    }

    transition: all 0.3s ease;
    display: flex;
    flex-direction: column;
    padding: var(--padding);
    text-decoration: none;
    gap: var(--gap);
    transform: scale(1);

    header {
        display: flex;
        justify-content: space-between;
    }

    .badge {
        padding: var(--badge-padding);
        font-size: 0.875rem;
        font-weight: bold;
        border-radius: 3rem;
    }

    time {
        font-size: var(--time-font-size);
    }

    .project-card-title {
        @extend .h5;

        margin-bottom: 1rem;
    }

    > div {
        flex: 1;

        p {
            margin: 0;
            hyphens: auto;
        }
    }

    > .btn {
        width: fit-content;
    }

    &:hover {
        color: var(--main-text-color);
        transform: scale(1.1);

        .btn {
            padding-right: 36px;
            padding-left: 0;

            .btn-icon--before {
                opacity: 0;
                transform: scaleX(0);
            }

            .btn-icon--after {
                opacity: 1;
                transform: scaleX(1);
            }
        }
    }
}
