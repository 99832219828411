.btn-primary {
    @include button-default-style;

    &:not(.btn-no-icons) {
        @include button-with-icons;
    }

    &:hover {
        background-color: $gray-800;
        border-color: $gray-800;
    }
}

.btn-light {
    @include button-default-style;

    &:not(.btn-no-icons) {
        @include button-with-icons;
    }

    &:hover {
        background-color: $gray-300;
        border-color: $gray-300;
    }
}

.btn-outline-primary {
    @include button-default-style;

    &:not(.btn-no-icons) {
        @include button-with-icons;
    }
}

.btn-tertiary {
    @include text-button-with-icons;
}

.btn-icon {
    position: absolute;
    display: inline-block;
    width: auto;
    height: 20px;
    fill: currentcolor;
    transition: transform 400ms cubic-bezier(0.86, 0, 0.07, 1),
        opacity 300ms cubic-bezier(0.86, 0, 0.07, 1);
}
