/** An L-shaped hook that is prepended for the element. */
@mixin l-hook {
    display: inline-flex;
    align-items: flex-end;
    margin-top: 2.5rem;

    &::before {
        content: "";
        position: relative;
        top: -1rem;
        display: block;
        width: 1.5rem;
        height: 3.375rem;
        border-bottom: 2px solid currentcolor;
        border-left: 2px solid currentcolor;
        margin-right: 0.5rem;
    }
}
