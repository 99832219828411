.youtube-embed {
    padding: 2.5rem 0;
}

.youtube-embed__wrapper {
    position: relative;
}

.youtube-embed__video_container {
    position: relative;
    z-index: 2;
    display: flex;
    overflow: hidden;
    width: 100%;
    height: auto;
    margin: auto;

    & > .youtube-embed__play-button {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.youtube-embed__play-button {
    @include button-reset;

    position: relative;
    z-index: 1;

    & > .youtube-embed__play-icon {
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
    }

    & > .youtube-embed__img {
        width: 100%;
        height: 100%;
    }
}

.youtube-embed__img {
    display: block;
    object-fit: cover;
}

.youtube-embed__play-icon {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: clamp(4.5rem, 3rem + 4.5vw, 7.5rem);
    height: clamp(4.5rem, 3rem + 4.5vw, 7.5rem);
    background-color: var(--labd-theme-color);
    color: $white;
    transition: background-color 200ms ease-out;
    border-radius: 50%;
    transform: translate(-50%, -50%);

    &::after {
        content: "";
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        margin-left: 0.25rem;
        background-color: black;
        mask: url("/static/labdigital/svgs/icon-play.svg") no-repeat center;
        mask-size: 1.5rem;
        mask-size: 30%;
    }
}

.youtube-embed__video {
    overflow: hidden;
    padding-top: 56.25%;

    & > iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.youtube-embed__caption {
    position: relative;
    z-index: 1;
    left: 1.5rem;
    display: flex;
    align-items: flex-end;
    width: 100%;
    height: calc(50% + 56px);
    background-color: $light;

    .caption-text {
        color: $dark;
    }

    @include media-breakpoint-up(md) {
        left: 3.625rem;
        width: calc(100% - 3.625rem);
        height: calc(50% + 72px);
    }

    @include media-breakpoint-up(lg) {
        left: 7.5rem;
        width: calc(100% - 7.5rem);
    }
}

.youtube-embed--captioned {
    .youtube-embed__video {
        z-index: 1;
    }

    .youtube-embed__inner {
        position: relative;

        &::after {
            content: "";
            position: absolute;
            z-index: 0;
            bottom: 0;
            left: 1.5rem;
            width: 100%;
            height: 50%;
            background-color: $light;
            border-top-left-radius: 0.25rem;
            border-top-right-radius: 0.25rem;

            @include media-breakpoint-up(md) {
                left: 3.625rem;
                width: calc(100% - 3.625rem);
            }

            @include media-breakpoint-up(lg) {
                left: 7.5rem;
                width: calc(100% - 7.5rem);
            }
        }
    }

    @include media-breakpoint-up(md) {
        .youtube-embed__video_container {
            width: 93%;
            margin: 0;
        }
    }
}
