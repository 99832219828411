.logo-list {
    position: relative;

    &__title,
    &__link {
        display: flex;
        align-items: center;
        column-gap: 10px;

        h2,
        .block-tertiary_button {
            flex: 0 0 auto;
        }

        hr {
            flex: 1 1 0;
            height: 2px;
        }
    }

    &__link hr {
        margin-top: 1.4rem;
    }

    &__items {
        display: flex;
        flex-flow: row wrap;
        row-gap: 10px;
        column-gap: 10px;
        justify-content: space-between;
    }

    &__item {
        display: flex;
        flex: 0 1 calc(50% - 5px);
        justify-content: center;

        @include media-breakpoint-up(md) {
            flex: 0 1 calc(33% - 10px);
        }

        @include media-breakpoint-up(lg) {
            flex: 0 1 calc(20% - 10px);
        }

        img {
            max-width: 100%;
            height: auto;
        }
    }
}
