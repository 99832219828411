.carousel-hero {
    position: relative;

    &__header {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: calc(var(--header-total-height-mobile) + var(--breadcrumbs-height-mobile) + 3vw) 0
            2.5rem 0;
        overflow-wrap: anywhere;
        gap: 1rem;
    }

    &__title {
        margin: 0;
        font-size: 3.25rem;
        line-height: 1;
        text-transform: lowercase;
    }

    &__description {
        font-size: 1.125rem;
        font-weight: 400;

        > p {
            margin: 0;
        }
    }

    &__carousel-items {
        display: flex;
        overflow-x: scroll;
        gap: 1.5rem;
        -ms-overflow-style: none;
        scrollbar-width: none;
        scroll-snap-type: x mandatory;
        scroll-behavior: smooth;

        ::-webkit-scrollbar {
            display: none;
        }

        > *:first-child {
            padding-left: var(--bs-gutter-x, 4vw);
        }

        > *:last-child {
            padding-right: var(--bs-gutter-x, 4vw);
        }
    }

    &__carousel-item {
        position: relative;
        text-decoration: none;
        scroll-snap-align: start;
    }

    &__carousel-item-image-wrapper {
        position: relative;
        aspect-ratio: 6 / 7;
        width: 70vw;
        max-width: 37.5rem;
        margin-bottom: 1.5rem;
    }

    &__carousel-item-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__carousel-item-title {
        margin-bottom: 0.5rem;
    }

    &__carousel-item-description {
        text-transform: lowercase;
    }

    &::before {
        content: "";
        position: absolute;
        z-index: -1; /* Ensure it stays behind the text */
        top: 0;
        right: 0; /* Ensure the pseudo-element covers the full width */
        bottom: 30%;
        left: 0;
        background-color: var(--main-color, var(--labd-theme-color));
    }

    @include media-breakpoint-up(lg) {
        &__header {
            gap: 1.5rem;
            padding: calc(
                var(--header-total-height-tablet) + var(--breadcrumbs-height-tablet) + 3vw
            )
                0 4rem 0;
        }

        &__carousel-item-image-wrapper {
            width: 60vw;
        }

        &__title {
            font-size: 5rem;
        }

        &__description {
            display: flex;
            flex: 1;
            align-items: flex-end;
            margin: 0;
            font-size: 1.25rem;
        }

        &__carousel-items {
            gap: 2rem;
        }
    }

    @include media-breakpoint-up(xl) {
        &__header {
            flex-direction: row;
            padding-top: calc(
                var(--header-total-height-desktop) + var(--breadcrumbs-height-desktop) + 3vw
            );
            padding-bottom: 5rem;
            gap: 2rem;
        }

        &__title {
            width: 55%;
            font-size: clamp(5.5rem, 6vw, 7rem);
        }

        &__description {
            display: flex;
            flex: 1;
            align-items: flex-end;
            margin: 0;
            font-size: 1.375rem;
        }

        &__carousel-item-image-wrapper {
            width: 28vw;
            max-width: 44rem;
        }

        &__carousel-items {
            gap: 5rem;
        }
    }

    @include media-breakpoint-up(xxl) {
        &__carousel-items {
            > *:first-child {
                padding-left: calc((100% - 1600px) / 2 + 1.5rem);
            }

            > *:last-child {
                padding-right: calc((100% - 1600px) / 2 + 1.5rem);
            }
        }
    }
}
