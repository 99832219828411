.three-column {
    .block-image {
        margin-bottom: 2rem;
    }

    &__title {
        margin-bottom: 4rem;
    }

    .btn {
        margin-top: 1rem;
    }

    @include media-breakpoint-up(lg) {
        [class^="col"] {
            &:nth-child(1) {
                padding-right: calc(var(--bs-gutter-x) * 1.5);
            }

            &:nth-child(2) {
                padding-right: var(--bs-gutter-x);
                padding-left: var(--bs-gutter-x);
            }

            &:nth-child(3) {
                padding-left: calc(var(--bs-gutter-x) * 1.5);
            }
        }
    }

    @include media-breakpoint-down(lg) {
        [class^="col"] {
            margin-bottom: 2rem;

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        .block-image {
            margin-bottom: 1.5rem;
        }

        .title {
            margin-bottom: 0.5rem !important;
        }
    }
}
