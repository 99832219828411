.footer {
    position: relative;
    padding-top: 80px;
    background-color: $black;
    color: $white;

    &__title {
        @extend .h1;

        margin-bottom: 32px;
        text-transform: lowercase;

        @include media-breakpoint-up(lg) {
            margin-bottom: 106px;
        }
    }

    &__secondary {
        position: relative;
        padding-top: 52px;
        padding-bottom: 52px;

        @include media-breakpoint-up(lg) {
            padding-top: 70px;
            padding-bottom: 70px;
            margin-top: 20px;
        }
    }

    &__scroll-btn {
        position: absolute;
        top: 50%;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 48px;
        height: 48px;
        border: none;
        transform: translate3d(0, -50%, 0);
        border-radius: 50%;
        user-select: none;
        cursor: pointer;
        vertical-align: middle;

        &:hover {
            opacity: 0.8;
        }

        .icon {
            transform: rotate(-45deg);
        }

        @include media-breakpoint-up(lg) {
            width: 56px;
            height: 56px;
        }
    }

    @include media-breakpoint-up(lg) {
        padding-top: 240px;

        &__title {
            margin-bottom: 110px;
        }
    }
}
