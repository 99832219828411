.usp-overview {
    &__content {
        padding-top: 32px;
        padding-bottom: 32px;
        border-bottom: 2px solid $gray-400;

        [class^="col-"]:last-of-type > .usp-list {
            margin-bottom: 0;
        }

        @include media-breakpoint-up(lg) {
            padding-top: 100px;
            padding-bottom: 100px;
        }
    }

    &__actions {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-top: 32px;

        .btn {
            margin-bottom: 1rem;
        }

        @include media-breakpoint-up(lg) {
            flex-direction: row;
            align-items: center;
            padding-top: 80px;

            .btn {
                margin-right: 4rem;
                margin-bottom: 0;
            }

            .btn-outline-primary {
                margin-right: 0;
                margin-left: auto;
            }
        }
    }
}
