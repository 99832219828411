@font-face {
    font-family: "TT Commons Pro Exp";
    font-style: normal;
    font-weight: normal;
    src: url("/static/labdigital/fonts/TTCommonsProExp-Rg.woff2") format("woff2"),
        url("/static/labdigital/fonts/TTCommonsProExp-Rg.woff") format("woff");
    font-display: swap;
}

@font-face {
    font-family: "TT Commons Pro Exp";
    font-style: italic;
    font-weight: normal;
    src: url("/static/labdigital/fonts/TTCommonsProExp-It.woff2") format("woff2"),
        url("/static/labdigital/fonts/TTCommonsProExp-It.woff") format("woff");
    font-display: swap;
}

@font-face {
    font-family: "TT Commons Pro Exp";
    font-style: normal;
    font-weight: bold;
    src: url("/static/labdigital/fonts/TTCommonsProExp-Bd.woff2") format("woff2"),
        url("/static/labdigital/fonts/TTCommonsProExp-Bd.woff") format("woff");
    font-display: swap;
}

@font-face {
    font-family: "TT Commons Pro Exp";
    font-style: italic;
    font-weight: bold;
    src: url("/static/labdigital/fonts/TTCommonsProExp-BdIt.woff2") format("woff2"),
        url("/static/labdigital/fonts/TTCommonsProExp-BdIt.woff") format("woff");
    font-display: swap;
}
