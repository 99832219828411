.responsive-video {
    position: relative;
    width: 100%;

    @include media-breakpoint-up(lg) {
        padding: 0 $grid-gutter-width;
    }

    @include media-breakpoint-up(xxl) {
        padding: 0 3.125rem;
    }
}
