.menu-desktop-panel {
    z-index: $zindex-fixed;
    display: flex;
    min-height: 300px; // FIXME: Temporary hack to always have the same height
    padding: 3rem 0;

    & > .menu-desktop-panel__buttons {
        flex: 0 1 0;
        margin-left: auto;
    }
}

.panel {
    &__sublink {
        margin: 0;
        font-size: 1.25rem;
        font-weight: normal;
        text-align: left;
        text-decoration: none;
    }

    .navigation-group {
        display: flex;
        flex: 1 0 0;
        flex-direction: column;
        gap: 0.5rem;
        padding: 0;
        list-style: none;
        margin: 0;
        text-align: left;
    }

    .empty {
        width: 350px;
    }

    &__content {
        display: flex;
        flex: 1;
        justify-content: space-between;
    }
}

.menu-desktop-panel__toggle-sub-nav,
.menu-desktop-panel__link {
    position: relative;
    display: flex;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
    color: inherit;
    font-size: 1.5rem;
    font-weight: $font-weight-bold;
    text-decoration: none;
    transition: padding-left 200ms ease-in-out;

    .icon {
        position: absolute;
        left: 0;
        width: 28px;
        height: auto;
        transform: scaleX(0);
        opacity: 0;
        transform-origin: left center;
        transition: transform 400ms cubic-bezier(0.86, 0, 0.07, 1),
            opacity 300ms cubic-bezier(0.86, 0, 0.07, 1);
    }

    &:hover,
    &:focus {
        padding-left: 44px;
        color: inherit;
        text-decoration: none;

        .icon {
            transform: scaleX(1);
            opacity: 1;
        }
    }

    &:hover {
        outline: none;
    }

    &:focus {
        outline: none;
    }
}

.menu-desktop-panel__toggle-sub-nav {
    @include button-reset;

    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-weight: $font-weight-normal;
}

// Explicity set ms styles for IE11,
// no idea why it's not correctly prefixed wth autoprefixer
// don't have the time to find out
.menu-desktop-panel__sub-navs {
    display: grid;
    -ms-grid-rows: 1;
    -ms-grid-columns: 1;
    grid-template: 1/1;

    & > .menu-desktop-panel__sub-nav {
        grid-area: 1/1;
        grid-column: 1;
        grid-row: 1;
    }
}

.menu-desktop-panel__sub-nav {
    // Setup for animation
    opacity: 0;
    pointer-events: none;
}

.menu-desktop-panel__buttons {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    & > :not(:last-child) {
        margin-bottom: 0.5rem;
    }
}
