.overlay-overview {
    --grid-gap: 1rem;

    display: flex;
    flex-direction: column;
    gap: var(--grid-gap);

    @include media-breakpoint-up(lg) {
        --grid-gap: 4rem;
    }

    h5 {
        text-transform: lowercase;
    }

    .full-screen-dialog > div {
        margin: 0 auto;

        > *:first-child {
            padding-top: 0;
            margin-top: 0;
        }
    }
}

.overlay-overview-grid {
    display: flex;
    gap: var(--grid-gap);
    flex-wrap: wrap;

    > * {
        > * {
            min-height: 15rem;
            @include media-breakpoint-up(lg) {
                min-height: 21.5rem;
            }
        }

        transition: transform 0.3s ease;

        &:hover {
            transform: scale(1.05);
            @include media-breakpoint-up(lg) {
                transform: scale(1.1);
            }
        }
    }

    @include card-grid;
}

.overlay-overview-card-icon {
    position: relative;
    width: 84px;
    height: 84px;
    background-color: currentcolor;
    mask-image: var(--icon);
    mask-repeat: no-repeat;
    mask-size: 80px 80px;
    mask-position: center center;

    // We add an invisible border to hide anti aliasing issues in the wiggle animation on Firefox.
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 84px;
        height: 84px;
        border: 2px solid var(--main-color);
    }
}

.overlay-overview-card {
    --padding-x: 1rem;
    --padding-y: 1rem;
    --gap: 1rem;

    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: var(--padding-y) var(--padding-x);
    text-decoration: none;
    gap: var(--gap);
    transition: all 0.3s ease;

    @include media-breakpoint-up(lg) {
        height: 22.5rem;
    }
    @include media-breakpoint-up(lg) {
        --padding-x: 2.5rem;
        --padding-y: 2rem;
        --gap: 2rem;
    }

    &-button {
        position: absolute;
        right: 0;
        bottom: 0;
        display: block;
        width: 3rem;
        height: 3rem;
        padding: 1rem;
        margin: 1rem;
        border-radius: 3rem;
        background-color: white;
        transition: all 0.3s ease;

        &::after {
            content: "";
            transition: background-color 0.3s ease;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: black;
            mask-image: url("/static/labdigital/svgs/icon-plus.svg");
            mask-position: center center;
            mask-repeat: no-repeat;
        }
    }

    &:hover {
        .overlay-overview-card-button {
            background-color: var(--accent-color);

            &::after {
                background-color: var(--accent-text-color);
            }
        }

        .overlay-overview-card-icon {
            animation: wiggle 1.5s infinite;
        }
    }
}

@keyframes wiggle {
    0% {
        transform: translateZ(1px) rotate(0deg);
    }

    80% {
        transform: translateZ(1px) rotate(0deg);
    }

    85% {
        transform: translateZ(1px) rotate(5deg);
    }

    95% {
        transform: translateZ(1px) rotate(-5deg);
    }

    100% {
        transform: translateZ(1px) rotate(0deg);
    }
}
