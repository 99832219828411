.quick-links {
    &__section {
        display: block;
        padding-top: 1.5rem;
        border-top: 2px solid rgba($black, 0.1);
    }

    &__nav {
        display: grid;
        gap: 0.625rem;
        grid-template-columns: 1fr;
        height: 100%;
    }

    &__header {
        display: grid;
        height: 100%;
        margin: 1rem 0;
        font-size: 1.125rem;
        font-weight: bold;
        text-align: left;
    }

    &__link {
        display: flex;
        align-self: center;
    }

    &__link a {
        display: flex;
        width: fit-content;
        font-weight: 400;
        text-decoration: none;
    }


    &__nav ul {
        display: grid;
        grid-template-columns: 1fr;
        list-style: none;
        padding: 0;
        margin: 0;
        gap: 1rem;
    }

    @include media-breakpoint-up(sm) {
        &__section {
            padding-top: 2rem;
        }

        &__header {
            font-size: 1.375rem;
        }
    }

    @include media-breakpoint-up(md) {
        &__nav ul {
            grid-template-columns: 1fr 1fr;
            gap: 1.5rem;
        }

        &__header {
            margin: 1.5rem 0;
        }
    }

    // Override of btn-tertiary hover animation on devices
    .btn-tertiary {
        @include media-breakpoint-down(lg) {
            &:hover {
                padding-left: 36px;

                .btn-icon--before {
                    opacity: 1;
                    transform: scaleX(1);
                }

                .btn-icon--after {
                    display: none;
                }
            }
        }

        @include media-breakpoint-down(sm) {
            &:hover {
                padding-left: 30px;
            }
        }
    
    }

    @include media-breakpoint-up(lg) {
        &__section {
            display: grid;
            grid-template-columns: 1fr 2fr;
            padding-top: 2.5rem;
        }

        &__nav {
            grid-column: auto;
        }

        &__header {
            align-self: top;
            margin: 0;
            font-size: 1.75rem;
        }
    }
}
