.breadcrumbs {
    &__breadcrumb {
        position: absolute;
        z-index: 100;
        top: var(--header-total-height-mobile);
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
        align-items: center;
        width: fit-content;
        padding: 1rem 0;
        font-size: 1rem;
        font-weight: 700;
        text-transform: lowercase;
    }

    &__breadcrumb a {
        display: flex;
        gap: 0.5rem;
        text-decoration: none;
    }

    &__breadcrumb-text {
        @include fancy-underline-on-hover;

        margin: 0;
    }

    &__breadcrumb-icon {
        display: flex;
        justify-content: center;
        align-self: center;
    }

    &__desktop-breadcrumb {
        display: none;
    }

    &__mobile-breadcrumb {
        display: flex;
        align-items: center;
    }

    &__home-icon {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 1.5rem;
    }

    &__home-icon-outline,
    &__home-icon-filled {
        position: absolute;
        display: flex;
        transition: opacity 0.3s ease;

        > svg {
            align-self: center;
        }
    }

    &__home-icon-outline {
        opacity: 1;
    }

    &__home-icon-filled {
        opacity: 0;
    }

    &__home-icon:hover &__home-icon-outline {
        opacity: 0;
    }

    &__home-icon:hover &__home-icon-filled {
        opacity: 1;
    }

    @include media-breakpoint-up(lg) {
        &__desktop-breadcrumb {
            top: var(--header-total-height-tablet);
            display: flex;
            padding: 2rem 0;
            margin: 0;
        }

        &__mobile-breadcrumb {
            display: none;
        }
    }

    @include media-breakpoint-up(xl) {
        &__desktop-breadcrumb {
            top: var(--header-total-height-desktop);
        }
    }
}
