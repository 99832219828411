.page-teaser {
    position: relative;
    display: block;
    overflow: hidden;
    background-color: black;
    color: white;

    &:not(.page-teaser--cta)::after {
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        height: 60%;
        background: linear-gradient(0deg, rgba(#050907, 1) 0%, rgba(#050907, 0) 100%);
        opacity: 0.4;
    }

    &--cta {
        background-color: var(--labd-theme-color);
        color: black;

        & .page-teaser__description::before {
            content: "";
            display: inline-block;
            width: 1.25rem;
            height: 1.25rem;
            background-image: url("/static/labdigital/svgs/icon-arrow-right.svg");
            background-size: 1.25rem;
            background-repeat: no-repeat;
            transform: rotate(-45deg);
            transform-origin: bottom;
        }
    }

    &:not(.page-teaser--cta):hover {
        color: white;
    }

    &__image {
        position: relative;
        display: block;
        opacity: 0.8;
        transition: transform 200ms ease-in;
        transform: scale(1);
    }

    &__title {
        @extend .h4;

        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        width: 90%;
        width: fit-content;
        margin-bottom: 0;
        text-align: center;
        text-transform: lowercase;
        transform: translate3d(-50%, -50%, 0);
    }

    &__description {
        position: absolute;
        z-index: 1;
        right: 0;
        bottom: 0;
        left: 0;
        padding: 0 24px 16px;
        margin-bottom: 0;
        font-size: 0.875rem;
        font-weight: bold;
    }

    @include media-breakpoint-up(lg) {
        &__item {
            &::after {
                height: 40%;
            }
        }

        &__description {
            padding: 0 54px 54px;
            font-size: 1.25rem;
        }
    }
}
