@use '../abstracts/functions/fluid-size' as *;

:root {
    --font-size-h1: clamp(4.5rem, min(12.5vw, 20vh), 17.5rem);
    --font-size-h2: clamp(4rem, 10.5vw, 12.5rem);
}

h1,
.h1 {
    font-size: var(--font-size-h1);
    line-height: 0.9;
    letter-spacing: $base-letter-spacing;
}

h2,
.h2 {
    font-size: var(--font-size-h2);
    line-height: 0.9;
    letter-spacing: $base-letter-spacing;
}

h3,
.h3 {
    font-size: clamp(3.25rem, 4.5vw, 5.5rem);
    line-height: 1.1;
    letter-spacing: $base-letter-spacing;
}

h4,
.h4 {
    font-size: clamp(2.5rem, 3.3vw, 4rem);
    line-height: 1.1;
    letter-spacing: $base-letter-spacing;
}

h5,
.h5 {
    font-size: clamp(1.5rem, 2vw, 2.5rem);
    line-height: 1.1;
    letter-spacing: $base-letter-spacing;
}

h6,
.h6 {
    font-size: clamp(1.25rem, 1.65vw, 2rem);
    line-height: 1.1;
}

body {
    font-size: 1.125rem;

    @include media-breakpoint-up(lg) {
        font-size: 1.5rem;
    }
}

.lead {
    font-size: clamp(1.5rem, 1.85vw, 2rem);
    font-weight: $font-weight-bold;
    line-height: 1.1;
    letter-spacing: $base-letter-spacing;
}

%emphasized {
    font-size: clamp(1.25rem, 3.75vw, 2rem);
    font-weight: bold;
    line-height: 1.4;
    letter-spacing: $base-letter-spacing;
}

.nav-title {
    color: hsl(0deg 0% 0% / 58%);
    font-size: #{fluid(14px, 24px)};
    font-weight: 700;
}
