.two-column {
    [class^="col-"] {
        &:first-of-type {
            margin-bottom: 0.5rem;
        }

        @include media-breakpoint-up(lg) {
            &:first-of-type {
                padding-right: 3rem;
            }

            &:last-of-type {
                padding-left: 3rem;
            }
        }
    }

    .image-block {
        max-width: unset;
        margin-bottom: 2rem;

        @include media-breakpoint-down(lg) {
            margin-bottom: 1.5rem;
        }
    }

    .block-primary_button {
        @include media-breakpoint-down(lg) {
            margin-bottom: 1.5rem;
        }
    }

    .btn {
        margin-top: 1rem;
    }

    &__bottom {
        @extend .mt-5;

        .divider {
            max-width: unset;
        }

        .image-block {
            margin-bottom: initial;
            text-align: center;

            img {
                margin: 0 auto;
            }
        }
    }

    &--with-pattern {
        position: relative;
        overflow: hidden;
        background-color: $white;

        &__content {
            position: relative;
            padding: min(5%, 10rem);
            background-color: $white;
        }
    }

    &__pattern {
        position: absolute;
        bottom: 0;
        left: 0;
        overflow: hidden;
        stroke: var(--labd-theme-color);
    }

    @include media-breakpoint-up(lg) {
        [class^="col-"]:first-of-type {
            margin-bottom: 0;
        }
    }
}
