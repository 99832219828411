.error-pages {
    position: relative;
    overflow: hidden;

    &__subtitle {
        @extend .h4;
    }

    &__pattern {
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;
        width: 240vw;
        stroke: var(--labd-theme-color);

        @include media-breakpoint-up(lg) {
            width: 130vw;
        }

        @include media-breakpoint-up(xxl) {
            top: auto;
            bottom: -400px;
        }
    }

    &__content {
        height: 50vh;
        min-height: 700px;

        @include media-breakpoint-up(xl) {
            min-height: 900px;
        }

        &--full-height {
            height: 100vh;
        }
    }
}
