@use "../../abstracts/functions/fluid-size" as *;

.header {
    --header-active-bg-color: var(--labd-theme-color);
    --header-active-color: var(--black);
    --header-scrolled-bg-color: rgb(255 255 255 / 95%);
    --header-scrolled-color: var(--black);
    --header-not-scrolled-bg-color: rgb(255 255 255 / 0%);
    --header-not-scrolled-color: var(--black);
    --offset: 10rem;

    position: absolute;
    z-index: $zindex-fixed;
    inset: 0 0 auto;
    transition-property: transform;
    transition-duration: 200ms;
    transition-timing-function: ease-out;
    display: grid;
    grid-template-columns:
        minmax(4vw, calc(50vw - 48.5rem))
        [contained-start] minmax(0, 1fr) [contained-end]
        minmax(4vw, calc(50vw - 48.5rem));

    .is-scrolling-up &,
    .is-scrolled &,
    .is-desktop-menu-open & {
        position: fixed;
    }

    .is-scrolled & {
        --offset: 6.5rem;

        background-color: var(--header-scrolled-bg-color);
        color: var(--header-scrolled-color);
    }

    .is-scrolled:not(.is-scrolling-up) & {
        &.header:not(.is-desktop-menu-open) {
            transform: translateY(-100%);
            transition-duration: 0ms;
        }
    }

    &__content {
        display: flex;
        grid-column: contained;
        justify-content: space-between;
        align-items: flex-end;
        height: var(--header-total-height-mobile);
        border-bottom: 2px solid rgba($black, 0.1);

        @include media-breakpoint-up(lg) {
            height: var(--header-total-height-tablet);
        }

        @include media-breakpoint-up(xl) {
            height: var(--header-total-height-desktop);
        }

        &.is-active {
            border-bottom: 2px solid rgba($black, 0);
        }

        .btn-outline-primary:not(.btn-no-icons) {
            svg {
                width: 1rem;
            }
        }

        .btn-outline-primary:not(.btn-no-icons) .btn-icon--before {
            left: 1.5rem;
        }

        .btn-outline-primary:not(.btn-no-icons):hover {
            padding-right: 1.25rem;
            padding-left: 3.875rem;
            background-color: unset;
            color: unset;
        }

        .button {
            height: 3rem;
            padding: 0 1.5rem;
            font-size: 1rem;

            span {
                width: fit-content;
                text-align: center;
                white-space: nowrap;
            }
        }

        > ul {
            display: none;
            align-items: center;
            padding: 0;
            margin: var(--header-padding-top-mobile) 0 var(--header-padding-bottom-mobile) 0;
            list-style: none;

            @include media-breakpoint-up(lg) {
                margin: var(--header-padding-top-tablet) 0 var(--header-padding-bottom-tablet) 0;
            }

            @include media-breakpoint-up(xl) {
                display: flex;
                flex: 1;
                justify-content: space-between;
                padding: 0;
                margin: 0;
            }

            > li {
                padding-bottom: var(--header-padding-bottom-desktop);
                margin: var(--header-padding-top-desktop) 0 0 0;
                text-align: left;

                @include media-breakpoint-up(xl) {
                    flex: 1 0 0;
                }

                &.has-subnav {
                    > a {
                        position: relative;
                    }

                    > nav {
                        display: none;
                    }

                    &:is(:hover, :focus-within) {
                        > nav {
                            display: block;
                        }

                        ~ :last-child {
                            &::before {
                                opacity: 1;
                            }
                        }
                    }

                    &:hover > a {
                        &::before {
                            content: "";
                            position: absolute;
                            z-index: 1;
                            right: -3.25rem;
                            width: 20rem;
                            height: 5rem;
                            clip-path: polygon(60% 0, 90% 0, 100% 100%, 0% 100%);
                        }
                    }

                    &.is-active {
                        .panel > * {
                            opacity: 1;
                        }
                    }
                }

                &:last-child {
                    padding-inline-end: 0;
                    display: flex;
                    justify-content: flex-end;

                    // This creates the background-color of the header when hovering a hassubnav list item
                    &::before {
                        content: "";
                        position: absolute;
                        z-index: -1;
                        background-color: var(--labd-theme-color);
                        inset: 0 0 auto;
                        opacity: 0;
                        transition: opacity 0.2s ease;
                        block-size: var(--header-total-height-desktop);
                    }
                }
            }
        }
    }

    .panel {
        position: absolute;
        top: var(--header-total-height-desktop);
        left: 0;
        display: grid;
        grid-template-columns:
            minmax(4vw, calc(50vw - 48.5rem)) [full-start] minmax(min-content, 1fr)
            minmax(40rem, 1fr) [full-end] minmax(4vw, calc(50vw - 48.5rem));
        overflow-y: auto;
        width: 100%;
        padding-bottom: 5rem;
        max-block-size: calc(100vh - var(--offset));
        max-block-size: calc(100dvh - var(--offset));
        background-color: var(--labd-theme-color);
        box-shadow: 0 1.5rem 2rem hsl(0deg 0% 0% / 8%);
        overscroll-behavior-y: contain;

        &__links {
            display: flex;
            grid-column: 2 / 4;
            width: 100%;
        }

        &__extra {
            --flow-spacer: 0.5rem;

            display: grid;
            grid-template-columns: repeat(
                auto-fill,
                minmax(clamp(15rem, -5rem + 26.67vw, 20rem), 1fr)
            );
            grid-column: full;
            column-gap: 1rem;
            margin-block-start: 5rem;
            padding-block-start: 5rem;
            border-block-start: 1px solid rgb(0 0 0 / 20%);
        }
    }
}

.header__logo {
    display: flex;
    align-self: flex-end;
    width: 350px;
    margin: var(--header-padding-top-mobile) 0 var(--header-padding-bottom-mobile) 0;

    @include media-breakpoint-up(lg) {
        margin: var(--header-padding-top-tablet) 0 var(--header-padding-bottom-tablet) 0;
    }

    @include media-breakpoint-up(xl) {
        height: 3rem;
        margin: var(--header-padding-top-desktop) 0 var(--header-padding-bottom-desktop) 0;

        .is-scrolled & {
            padding-block-start: 2rem;
        }
    }

    img {
        display: flex;
        align-self: flex-start;
        width: auto;
        height: 2rem;

        @include media-breakpoint-up(xl) {
            height: 3rem;
        }
    }
}

.header__toggle {
    display: flex;
    align-self: center;
    width: 1.5rem;
    height: 1.5rem;

    .icon {
        width: 17.5px;
        height: 17.5px;
    }

    @include media-breakpoint-up(xl) {
        display: none;
    }
}

.header__nav-link {
    display: flex;
    padding: 0;
    font-size: 1.25rem;
    font-weight: bold;
    line-height: 2;
    text-align: center;
    text-decoration: none;
}

.primary-link {
    --spacing: #{fluid(16px, 24px)};

    position: relative;
    display: flex;
    align-items: flex-start;
    padding: var(--spacing);
    border: 2px solid hsl(0deg 0% 0%);
    text-decoration: none;
    gap: var(--spacing);

    @include media-breakpoint-up(xl) {
        max-inline-size: 40rem;
        inline-size: 100%;
        margin-inline-start: auto;
    }

    @include media-breakpoint-down(xl) {
        &[data-order="last"] {
            order: 1;
        }
    }

    &__body {
        flex: 1;
        max-inline-size: 20ch;
        margin-inline-end: auto;
    }

    &__title {
        margin: 0;
        font-size: #{fluid(32px, 40px, 1200px, 1500px)};
        font-weight: 700;
        line-height: 1.2;
        padding-block-end: #{fluid(40px, 48px)};

        @include media-breakpoint-down(xl) {
            font-size: #{fluid(20px, 32px)};
        }
    }

    > img {
        transition: transform 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
        inline-size: #{fluid(120px, 200px)};
        block-size: auto;
        object-fit: cover;
        aspect-ratio: 1;
    }

    .icon {
        position: absolute;
        bottom: var(--spacing);
        left: var(--spacing);
        transition: scale 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    &[target="_blank"] {
        .icon {
            transform: rotate(-45deg);
        }
    }

    @media (hover: hover) and (pointer: fine) {
        &:is(:hover, :focus-visible) {
            > img {
                transform: scale(1.05);
            }

            > .icon {
                scale: 1.3;
            }
        }
    }
}

// NAVLIST IN PANEL
.nav-list {
    $self: &;

    --size: #{fluid(32px, 40px, 1200px, 1500px)};
    --gap: 1rem;

    display: grid;
    gap: var(--gap);
    padding: 0;
    margin: 0;
    list-style: none;

    &__link {
        position: relative;
        display: flex;
        align-items: center;
        color: currentcolor;
        font-size: var(--size);
        font-weight: $font-weight-bold;
        line-height: 1;
        text-decoration: none;
        transition: padding-left 200ms ease-in-out;

        @include media-breakpoint-up(xl) {
            display: inline-flex;
        }

        .icon {
            position: absolute;
            left: 0;
            width: #{fluid(20px, 28px)};
            height: auto;
            transform: scaleX(0) rotate(0deg);
            opacity: 0;
            transform-origin: left center;
            transition: transform 400ms cubic-bezier(0.86, 0, 0.07, 1),
                opacity 300ms cubic-bezier(0.86, 0, 0.07, 1);

            [data-list="compact"] & {
                width: #{fluid(16px, 20px)};
            }
        }

        &[target="_blank"] {
            .icon {
                transform: rotate(-45deg) scaleX(0);
                transform-origin: center;
            }
        }

        @media (hover: hover) and (pointer: fine) {
            &:is(:hover, :focus-visible) {
                outline: none;
                padding-inline-start: #{fluid(24px, 34px)};
                color: inherit;
                text-decoration: none;

                [data-list="compact"] & {
                    padding-inline-start: #{fluid(22px, 28px)};
                }

                .icon {
                    transform: scaleX(1);
                    opacity: 1;
                }

                &[target="_blank"] {
                    .icon {
                        transform: rotate(-45deg) scaleX(1);
                    }
                }
            }
        }

        @media (hover: none) and (pointer: coarse) {
            &[target="_blank"] {
                padding-inline-start: #{fluid(28px, 44px)};

                [data-list="compact"] & {
                    padding-inline-start: #{fluid(22px, 28px)};
                }

                .icon {
                    opacity: 1;
                    transform: rotate(-45deg) scaleX(1);
                }
            }
        }
    }

    &[data-list="compact"] {
        --size: #{fluid(16px, 20px)};
        --gap: 0.5rem;

        > li {
            border-block: 0;
        }
    }

    @include media-breakpoint-down(xl) {
        --size: #{fluid(20px, 32px)};
        --gap: 0;

        & + & {
            margin-block-start: 0.5rem;
        }

        > li {
            border-block: 2px solid hsl(0deg 0% 0% / 20%);

            + li {
                border-block-start-width: 0;
            }
        }

        &__link {
            line-height: 1.5;
        }

        &:not([data-list="compact"]) {
            #{$self}__link {
                padding-block: 1rem;
            }
        }
    }
}
