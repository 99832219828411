.quote {
    @include media-breakpoint-up(lg) {
        width: 100%;
        max-width: 64rem;
        margin: 0 auto;
    }

    &__inner {
        display: flex;
        flex-direction: column;
    }

    &__blockquote {
        font-size: clamp(1.25rem, 3.5vw, 2rem);
        line-height: 1.5;
    }

    &__quotation-mark {
        flex-shrink: 0;
        width: clamp(48px, 4vw, 68px);
        margin-bottom: 20px;

        & > svg {
            transform: translateY(0.2rem);
            width: 100%;
            height: auto;
            color: var(--icon-color, var(--labd-theme-color));

            @include media-breakpoint-up(md) {
                transform: translateY(0.4rem);
            }
        }
    }

    @include media-breakpoint-up(lg) {
        &__inner {
            flex-direction: row;
        }

        &__quotation-mark {
            margin-right: clamp(48px, 4vw, 68px);
            margin-bottom: 0;
        }
    }
}
