.block {
    padding-top: $block-spacing-mobile;
    padding-bottom: $block-spacing-mobile;

    @include media-breakpoint-up(md) {
        padding-top: $block-spacing-tablet;
        padding-bottom: $block-spacing-tablet;
    }

    @include media-breakpoint-up(lg) {
        padding-top: $block-spacing-desktop;
        padding-bottom: $block-spacing-desktop;
    }

    .page-content & {
        padding-top: $block-spacing-mobile-contentpage;
        padding-bottom: $block-spacing-mobile-contentpage;

        @include media-breakpoint-up(md) {
            padding-top: $block-spacing-tablet-contentpage;
            padding-bottom: $block-spacing-tablet-contentpage;
        }

        @include media-breakpoint-up(lg) {
            padding-top: $block-spacing-desktop-contentpage;
            padding-bottom: $block-spacing-desktop-contentpage;
        }
    }
}

.block-top {
    padding-top: $block-spacing-mobile;

    @include media-breakpoint-up(md) {
        padding-top: $block-spacing-tablet;
    }

    @include media-breakpoint-up(lg) {
        padding-top: $block-spacing-desktop;
    }

    .page-content & {
        padding-top: $block-spacing-mobile-contentpage;

        @include media-breakpoint-up(md) {
            padding-top: $block-spacing-tablet-contentpage;
        }

        @include media-breakpoint-up(lg) {
            padding-top: $block-spacing-desktop-contentpage;
        }
    }
}

.block-bottom {
    padding-bottom: $block-spacing-mobile;

    @include media-breakpoint-up(md) {
        padding-bottom: $block-spacing-tablet;
    }

    @include media-breakpoint-up(lg) {
        padding-bottom: $block-spacing-desktop;
    }

    .page-content & {
        padding-bottom: $block-spacing-mobile-contentpage;

        @include media-breakpoint-up(md) {
            padding-bottom: $block-spacing-tablet-contentpage;
        }

        @include media-breakpoint-up(lg) {
            padding-bottom: $block-spacing-desktop-contentpage;
        }
    }
}
