.pagination-view {
    --grid-gap: 1rem;

    @include media-breakpoint-up(lg) {
        --grid-gap: 4rem;
    }

    gap: var(--grid-gap);
    display: flex;
    flex-direction: column;
    scroll-margin-top: 6.5rem;

    header {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        justify-content: space-between;
        align-items: center;
        height: min-content;

        > div {
            display: flex;
            align-items: center;
            gap: 1rem;
        }

        h4 {
            text-transform: lowercase;
        }
    }

    select {
        position: relative;
        width: 100%;
        padding: 1rem 2rem;
        padding-right: 6rem;
        border: 2px solid black;
        background: transparent;
        background-image: url("/static/labdigital/svgs/icon-chevron-down.svg"),
            linear-gradient(black, black);
        background-size: 1rem, 2px 100%;
        background-repeat: no-repeat;
        color: black;
        font-size: 1rem;
        font-weight: bold;
        appearance: none;
        background-position-y: center;
        background-position-x: calc(100% - 1.5rem), calc(100% - 4rem);
        border-radius: 1rem;

        @include media-breakpoint-up(lg) {
            width: unset;
            font-size: 1.25rem;
        }
    }

    select::after {
        content: "";
        position: absolute;
        top: 0;
        right: -2rem;
        display: block;
        width: 2px;
        height: 100%;
        background-color: black;
    }

    .counter {
        width: 2.5rem;
        height: 2.5rem;
        padding: 0;
        background-color: var(--labd-theme-color);
        color: black;
        font-size: 1rem;
        font-weight: bold;
        line-height: 2.5rem;
        text-align: center;
        border-radius: 100%;

        @include media-breakpoint-up(lg) {
            width: 4.5rem;
            height: 4.5rem;
            font-size: 2rem;
            line-height: 4.5rem;
        }
    }
}

.pagination-grid {
    display: flex;
    gap: var(--grid-gap);
    flex-wrap: wrap;

    > * {
        min-height: 21.5rem;
    }

    @include card-grid;

    @include media-breakpoint-up(lg) {
        > * {
            min-height: 40rem;
        }
    }
}

.pagination-paginator {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    gap: 3rem;

    & button:first-child svg {
        transform: translate(-0.25rem, -0.125rem);
    }

    & button:last-child svg {
        transform: translate(0.15rem, -0.125rem);
    }

    & button {
        width: 4rem;
        height: 4rem;
        padding: 0;
        border: 2px solid black;
        background-color: transparent;
        border-radius: 4rem;
    }

    & button:disabled {
        opacity: 0.5;
    }

    @include media-breakpoint-up(md) {
        justify-content: center;
    }
}
