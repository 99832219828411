.footer-partners-certification {
    border-bottom: 1px solid rgb(255 255 255 / 20%);

    &__header {
        display: flex;
        align-items: center;
        color: rgba($white, 0.6);

        &::after {
            content: "";
            display: block;
            flex-grow: 1;
            height: 1px;
            margin-left: 12px;
            background-color: $gray-800;
        }
    }

    &__layout {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        padding: 40px 0;
        gap: 30px;

        @include media-breakpoint-up(xl) {
            grid-template-columns: repeat(6, 1fr);
        }
    }

    &__item {
        display: flex;
        justify-content: center;
        align-items: center;

        & > svg {
            max-width: 100%;
            height: auto;
        }
    }
}
