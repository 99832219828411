.indented-block {
    padding: clamp(20px, 2vw, 40px);
    margin-bottom: 2rem;
    background-color: var(--labd-theme-color);

    @include media-breakpoint-up(lg) {
        margin-left: 3.5rem;
    }

    &__title {
        margin-bottom: clamp(60px, 5vw, 72px);
    }

    hr {
        height: 2px;
        opacity: 1;
    }

    &__link {
        display: block;
        width: fit-content;
        margin-left: auto;
    }
}
