@use "../abstracts/functions/fluid-size" as *;

.menu-mobile {
    position: fixed;
    z-index: $zindex-fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: none;
    transform: translateX(-100%);

    .icon-button {
        width: unset;
    }

    &__fade-icon {
        transition: opacity 0.5s ease;
        position: relative;
    }

    &__close-icon {
        opacity: 1;
        margin-right: -1.5rem;
    }

    &__arrow-icon {
        opacity: 0;
    }

    &__close:hover &__close-icon {
        opacity: 0;
    }

    &__close:hover &__arrow-icon {
        opacity: 1;
    }

    &__some-icon {
        position: relative;
        transition: opacity 0.5s ease;
    }

    &__chevron {
        position: relative;
        display: flex;
        align-items: center;
    }

    &__chevron-icon-down {
        z-index: 2;
        margin-right: -1.5rem;
        opacity: 1;
    }

    &__chevron-icon-up {
        z-index: 1;
        opacity: 0;
    }

    &__link[data-open="true"] .menu-mobile__chevron-icon-up {
        opacity: 1;
    }

    &__link[data-open="true"] .menu-mobile__chevron-icon-down {
        opacity: 0;
    }

    &__drawer {
        display: flex;
        overflow: hidden;
        width: 100%;
        max-height: 0;
        margin-left: 0.5rem;
        transition: max-height 0.3s ease;
        gap: 1rem;
    }

    &__sublinks {
        display: flex;
        flex-direction: column;
        font-size: 1.125rem;
        gap: 1rem;
    }

    &__list {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        margin: 0;
    }

    &__sublink {
        text-decoration: none;

        span {
            font-weight: normal;
        }
    }

    &__drawer--open {
        margin: 0 0 1.5rem 0.5rem;
    }

    &__list-item:not(.button-wrapper) {
        border-top: 2px solid rgba($black, 0.1);
        text-decoration: none;

        &:last-of-type {
            border-bottom: 2px solid rgba($black, 0.1);
        }
    }

    &__panel {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        width: 90%;
        background-color: $white;
        box-shadow: 0 0 32px 0 rgba($black, 0.16);

        &__buttons {
            display: flex;
            justify-content: center;
            width: 100%;
            gap: 1rem;
        }

        .btn-outline-primary:not(.btn-no-icons):hover {
            padding-right: 1.5rem;
            padding-left: 4rem;
            background-color: unset;
            color: unset;
        }

        .btn-outline-primary:not(.btn-no-icons) .btn-icon--before {
            opacity: 1;
            left: 1.5rem;
        }

        .button {
            display: flex;
            flex: 1;
            justify-content: center;
            width: auto;
            height: 3rem;
            padding: 0;
            margin: 2rem 0;
            font-size: 1rem;
            text-align: center;

            &:hover {
                .btn-icon--before {
                    opacity: 1;
                    transform: scaleX(1);
                }

                .btn-icon--after {
                    opacity: 0;
                    transform: scaleX(0);
                }
            }
        }

        .button-wrapper {
            width: 100%;
        }

        @include media-breakpoint-up(md) {
            width: 50%;
        }
    }

    &__header {
        flex-shrink: 0;
        // Due to border, looks very slightly misaligned, thus the calculation
        height: calc(var(--header-total-height-mobile) - 2px);

        @include media-breakpoint-up(lg) {
            height: calc(var(--header-total-height-tablet) - 2px);
        }
    }

    &__header-back {
        @include button-reset;

        height: 42px;
        color: $black;
        font-size: 0.875rem;
        font-weight: $font-weight-bold;
        line-height: 1;

        .icon {
            width: 12px;
            height: auto;
        }

        @include media-breakpoint-up(md) {
            font-size: 1.25rem;
        }
    }

    &__scroll {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        overflow-y: auto;
        overscroll-behavior: contain;
        gap: #{fluid(32px, 48px)};
    }

    &__open-sub-panel {
        @include button-reset;

        display: flex;
        justify-content: flex-end;
        align-items: center;
        align-self: stretch;
        width: 80px;
    }

    &__link {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 1rem 0;
        border: 0;
        background-color: transparent;
        color: $black;
        font-size: 1.125rem;
        font-weight: $font-weight-bold;
        line-height: 1;
        padding-inline: 0;

        &:hover,
        &:link {
            color: $black;
            text-decoration: none;
        }

        @include media-breakpoint-up(md) {
            padding: 1.5rem 0;
        }

        &[target="_blank"] {
            justify-content: flex-start;
            column-gap: 0.5rem;

            .icon {
                rotate: -45deg;
            }
        }
    }

    &__chevron-right {
        .icon {
            width: 0.75rem;
            height: auto;
        }

        @include media-breakpoint-up(md) {
            .icon {
                width: 1rem;
            }
        }
    }

    &__link--parent {
        font-weight: $font-weight-bold;
    }

    &__quicklinks {
        display: flex;
        margin-top: auto;

        .btn {
            padding-right: 82px;
            padding-left: 24px;

            .btn-icon--before {
                left: 16px;
            }

            .btn-icon--after {
                right: 16px;
            }
        }

        @include media-breakpoint-up(sm) {
            .btn {
                padding-top: 16px;
                padding-bottom: 16px;
            }
        }
    }

    &__extra-links {
        order: 10;

        .nav-title + .nav-list {
            margin-block-start: #{fluid(12px, 16px)};
        }

        .nav-list + .nav-title {
            margin-block-start: #{fluid(24px, 32px)};
        }
    }
}
