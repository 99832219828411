.overview {
    &__inner {
        padding-top: 72px;
        padding-bottom: 80px;
    }

    &__title {
        margin-bottom: 32px;
    }

    &__row {
        padding-top: 24px;
        padding-bottom: 36px;
        border-top: 2px solid $black;

        .row {
            @include media-breakpoint-up(lg) {
                --bs-gutter-x: 3rem;
            }
        }

        &:last-of-type {
            padding-bottom: 0;
        }
    }

    &__list {
        @include list-unstyled;

        margin-bottom: 20px;
    }

    &__list-item {
        @include list-icon-diamond($color: $black);

        &:not(:last-of-type) {
            margin-bottom: 0.3rem;
        }
    }

    @include media-breakpoint-up(lg) {
        padding-right: $grid-gutter-width;
        padding-left: $grid-gutter-width;

        &__inner {
            padding-top: 96px;
            padding-bottom: 120px;
        }

        &__title {
            margin-bottom: 64px;
        }

        &__row {
            padding-top: 48px;
            padding-bottom: 72px;
        }

        &__list {
            margin-bottom: 0;
        }

        &__list-item:not(:last-of-type) {
            margin-bottom: 10px;
        }
    }

    @include media-breakpoint-up(xxl) {
        padding-right: 3.125rem;
        padding-left: 3.125rem;
    }
}
