.timeline-open-button {
    --size: clamp(10rem, 30vw, 20rem);
    @include breathing();
}

.timeline {
    --cta-size: clamp(12.5rem, 37.5vw, 20rem);

    position: relative;
    display: flex;
    flex-direction: column;
    width: fit-content;
    padding: 2rem 1rem;
    padding-bottom: calc(var(--cta-size) + 2rem);

    --header-width: calc(100vw - 5rem);

    @include media-breakpoint-up(lg) {
        flex-direction: row;
        align-items: center;
        max-height: 100vh;
        padding-right: var(--cta-size);
        padding-bottom: 2rem;

        --header-width: max(52.5rem, 50vw);
    }

    &-header {
        width: var(--header-width);
        @include media-breakpoint-up(lg) {
            position: fixed;
            // we want padding to not be included in the width, because the padding is animated
            box-sizing: content-box;
            padding-left: calc((1 - 0.001 * var(--scroll-x)) * 6vw);
            opacity: calc(1 - 0.0015 * var(--scroll-x));
        }
    }

    &-description {
        @include media-breakpoint-up(lg) {
            width: calc(var(--header-width) - 6vw);
        }
    }

    &-title {
        margin-bottom: 2rem;
        text-transform: lowercase;

        @include media-breakpoint-up(md) {
            margin-bottom: 2.5rem;
        }
        @include media-breakpoint-up(lg) {
            margin-bottom: 3.5rem;
        }
    }

    &-call-to-action-container {
        position: absolute;
        bottom: 0;

        // Exclude landscape-mobile for
        @media (min-height: 800px) {
            right: 0;
            height: 100%;
            padding-top: 300vh;
        }

        @include media-breakpoint-up(lg) {
            width: 100%;
            height: unset;
            padding-top: unset;
            padding-left: 300vw;
        }
    }

    &-call-to-action {
        --size: var(--cta-size);

        position: sticky;
        z-index: 10;
        top: calc(var(--vh, 1vh) * 100 - var(--size) - 2rem);
        right: -2rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: var(--size);
        height: var(--size);
        padding: 2rem;
        background-color: white;
        color: black;
        text-decoration: none;
        border-radius: var(--size);

        // iOS Safari fix for overhanging navigation bar at the bottom.
        @supports (-webkit-touch-callout: none) {
            top: calc(100vh - var(--size) - 6rem);
            margin-bottom: 6rem;
        }

        &::before {
            --main-color: var(--main-text-color);

            content: "";
            position: absolute;
            top: 0;
            right: 0;
            display: block;
            width: var(--size);
            height: var(--size);
            background-color: var(--main-color);
            border-radius: var(--size);
        }

        &:hover::before {
            animation: inhale 0.5s;
            transform: scale(1.1);
        }

        @include breathing;

        @include media-breakpoint-up(lg) {
            top: unset;
            left: calc(100vw - 25rem);
        }

        &-text {
            @extend %emphasized;

            position: relative;
            text-align: center;
        }

        &-button {
            position: relative;
            display: flex;
            align-items: center;
            font-size: clamp(14px, 1.5vw, 18px);
            font-weight: bold;

            & span {
                display: block;
            }

            & svg {
                display: block;
                width: clamp(14px, 1.5vw, 18px);
                height: clamp(14px, 1.5vw, 18px);
                margin-left: 0.5rem;
                line-height: 1;
            }
        }
    }

    &-body {
        position: relative;
        @include media-breakpoint-up(lg) {
            padding-left: var(--header-width);
        }
    }

    --font-size: clamp(4rem, 10.5vw, 12.5rem);

    &-phases {
        position: relative;
        display: flex;
        flex-direction: column;
        margin-top: 1rem;

        @include media-breakpoint-up(lg) {
            flex-direction: row;

            // Margin for the overhanging phase-title, which is --font-size * 0.3
            margin-top: calc(var(--font-size) * 0.69);
        }

        &-title {
            position: fixed !important;
            top: 6rem !important;
            right: 1rem;
            padding-top: calc((1 - 0.002 * (var(--scroll-y) - 0)) * max(30rem, 50vh));
            margin: 0;
            color: white;
            writing-mode: vertical-rl;
            transition: all 0.1s;

            & .overhang-clone-letter {
                bottom: 0;
            }

            @include media-breakpoint-up(md) {
                right: 2rem;
            }

            @include media-breakpoint-up(lg) {
                top: unset !important;
                right: unset;
                left: 10vw;
                padding-top: unset;
                padding-left: calc((1 - 0.001 * (var(--scroll-x) - 300)) * 6vw);
                opacity: calc(0.001 * var(--scroll-x) - 0.5);
                writing-mode: unset;
            }
        }
    }

    & .phase + .phase {
        position: relative;

        &::before {
            @extend %emphasized;

            content: attr(divider-text);
            margin: 2rem 0 0.5rem;
            color: white;
        }

        @include media-breakpoint-up(md) {
            &::before {
                margin: 3rem 0 0.5rem;
            }
        }
        @include media-breakpoint-up(lg) {
            border-left: 2px dashed white;

            &::before {
                position: absolute;
                top: 50%;
                left: -3.75rem;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 7.5rem;
                height: 7.5rem;
                padding: 1rem;
                border: 2px dashed white;
                margin-top: -3.75rem;
                background-color: black;
                font-size: 1.25rem;
                text-align: center;
                border-radius: 7.5rem;
            }
        }
    }

    &-scroll-button {
        display: none;
        justify-content: center;
        padding: 0;
        color: var(--labd-theme-color);
        font-size: 1.25rem;
        font-weight: bold;
        line-height: 1.4rem;

        & svg {
            margin-left: 0.5rem;
        }

        @include media-breakpoint-up(lg) {
            display: block;
        }
    }
}

.phase {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(lg) {
        padding: 0 6.75rem;
    }

    &-content {
        position: relative;
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;

        @include media-breakpoint-up(lg) {
            flex-direction: column;
        }
    }

    &-items {
        position: relative;
        display: flex;
        gap: 3.5rem;
        flex-direction: column;

        @include media-breakpoint-up(lg) {
            flex-direction: row;
            max-height: calc(100vh - 18rem);
            gap: 7.5rem;
        }
    }

    &-item {
        img {
            // Margin for the overhanging phase-title, which is --font-size * 0.3
            width: calc(100% - var(--font-size) * 0.69);
            height: auto;

            @include media-breakpoint-up(lg) {
                width: var(--width, auto);
                height: 100%;
            }
        }
    }

    &-description-container {
        position: relative;
        width: 100%;

        @include media-breakpoint-up(lg) {
            order: 1;
        }
    }

    &-description {
        @extend %emphasized;

        position: sticky;
        left: 10vw;
        margin-right: 4rem;
        margin-bottom: 2rem;

        @include media-breakpoint-up(lg) {
            @include l-hook;

            margin-right: 0;
            margin-bottom: 0;
            font-size: 1.5rem;
        }
    }
}

.timeline-dialog-content {
    @include media-breakpoint-up(lg) {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}
