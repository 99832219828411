.contributors {
    --padding-x: 1.5rem;
    --padding-y: 1.5rem;
    --gap: 0.75rem;

    @include media-breakpoint-up(md) {
        --gap: 1rem;
    }
    @include media-breakpoint-up(lg) {
        --padding-x: 3rem;
        --padding-y: 3rem;
        --gap: 2rem;
    }

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: var(--padding-y) var(--padding-x);
    gap: var(--gap);
    border: 2px solid hsl(0deg 0% 0%);

    > h3 {
        @extend .h4;

        flex-basis: 100%;
        margin: 0;
        text-transform: lowercase;
    }

    &__body {
        display: flex;
        flex: 1 1 0%;

        img {
            display: block;
            width: clamp(4rem, 2rem + 8vw, 7.5rem);
            border: 0.25rem solid white;
            margin-right: -1.5rem;
            aspect-ratio: 1;
            border-radius: 7.5rem;
            object-fit: cover;
            object-position: top center;
        }
    }

    &__cta {
        display: flex;
        flex: 1 1 20rem;
        flex-wrap: wrap;
        align-items: center;
        height: min-content;
        gap: 1rem 2rem;
        margin-top: 1rem;

        @media (min-width: 824px) {
            justify-content: flex-end;
        }
    }
}
