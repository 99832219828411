@mixin breathing() {
    &::before {
        animation: breathe 4s infinite;
    }
}

@keyframes breathe {
    0% {
        transform: scale(1);
        opacity: 0.7;
        box-shadow: 0 0 0 0 var(--main-color);
    }

    70% {
        transform: scale(1.01);
        opacity: 0;
        box-shadow: 0 0 0 1.5rem var(--main-color);
    }

    100% {
        transform: scale(1);
        opacity: 0;
        box-shadow: 0 0 0 0 var(--main-color);
    }
}

@keyframes inhale {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(1.1);
    }
}
