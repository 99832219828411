.text-hero {
    position: relative;

    .text-hero__title {
        line-height: 1;
        text-transform: lowercase;
    }

    &--small {
        padding-bottom: 64px;
        margin-top: 214px;

        .text-hero__title {
            font-size: clamp(3.25rem, 7.5vw, 5.5rem);
        }
    }

    &--large {
        // for the overhang-letter downwards shift
        --font-size: var(--font-size-h1);

        padding-bottom: 0;
        margin-top: 120px;

        .text-hero__title {
            font-size: var(--font-size);
            line-height: 0.9;
        }

        & + .large-image,
        + .photo-grid {
            padding-top: 0;
        }
    }

    @include media-breakpoint-up(lg) {
        &--small {
            padding-bottom: 100px;
            margin-top: 284px;
        }

        &--large {
            // compensate for overhang-letter downwards shift
            margin-top: calc(16vw - var(--font-size) * 0.16);
        }
    }
}
