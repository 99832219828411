.eday-button {
    position: absolute;
    top: 70px;
    right: 20px;
    width: 150px;
    height: 150px;
    background-color: #ff56cb;
    line-height: 150px;
    text-align: center;

    svg {
        max-width: 75%;
    }

    @include media-breakpoint-up(sm) {
        top: auto;
        right: 200px;
        bottom: 250px;
    }

    @include media-breakpoint-up(md) {
        right: 250px;
    }

    @include media-breakpoint-up(xl) {
        right: 350px;
        width: 240px;
        height: 240px;
        line-height: 240px;
    }
}
