.button-list {
    @include list-unstyled;

    display: flex;
    flex-flow: wrap column;
    margin-top: 2rem;

    @include media-breakpoint-up(lg) {
        width: 100%;
        max-width: 64rem;
        margin: 3rem auto 0;
    }

    &__item {
        align-self: stretch;
        margin-bottom: 1rem;
        text-align: center;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    .btn-primary,
    .btn-outline-primary {
        width: 100%;
    }

    @include media-breakpoint-up(lg) {
        flex-direction: row;
        align-items: center;

        &__item {
            align-self: auto;
            margin-right: 1.5rem;
            margin-bottom: 0;

            &:last-of-type {
                margin-right: 0;
            }
        }

        .btn-primary,
        .btn-outline-primary {
            width: auto;
        }
    }
}
