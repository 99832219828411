.footer-legal {
    display: grid;
    grid-template-columns: 1fr max-content;
    grid-template-areas:
        "copyright copyright"
        "kvk kvk"
        "nav nav";
    font-size: 0.875rem;
    line-height: 28px;
    gap: 10px;

    @include media-breakpoint-up(md) {
        grid-template-columns: max-content max-content 1fr;
        grid-template-areas:
            "copyright kvk"
            "nav nav";
        gap: 20px;
    }

    @include media-breakpoint-up(xl) {
        grid-template-columns: max-content max-content max-content 1fr;
        grid-template-areas: "copyright kvk nav";
        align-items: center;
        font-size: 1.125rem;
        line-height: 32px;
        gap: 45px;
    }

    & > .footer-legal__copyright {
        grid-area: copyright;
    }

    & > .footer-legal__kvk {
        grid-area: kvk;
    }

    & > .footer-legal__nav {
        grid-area: nav;
    }
}

.footer-legal__kvk,
.footer-legal__copyright {
    color: rgba($white, 0.8);
}
