dialog {
    position: fixed;
    z-index: 10000;
    display: none;
    padding: 0;
    border: none;
    transform: translateZ(0);
    -ms-overflow-style: none;
}

dialog[open] {
    display: block;
}

dialog > button {
    $close-btn-size: clamp(50px, 6vw, 5rem);

    transform: translateZ(0);
    position: fixed;
    z-index: 10;
    top: 0;
    right: 0;
    display: block;
    width: $close-btn-size;
    height: $close-btn-size;
    padding: 0;
    border: 2px solid var(--accent-color, black);
    margin: clamp(1rem, 3vw, 2.5rem);
    background-color: var(--main-color, var(--labd-theme-color));
    border-radius: 100px;

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--accent-color, black);
        mask: url("/static/labdigital/svgs/icon-cross.svg") no-repeat center center;
    }

    &:focus {
        box-shadow: 0 0 0 0.25rem rgb(0 0 0 / 25%);
    }

    &:focus-visible {
        outline: none;
    }
}

.full-screen-dialog-opener {
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    transform: translateZ(0);

    &::before {
        content: "";
        position: fixed;
        top: calc(var(--origin-y, 50vh) - var(--size));
        left: calc(var(--origin-x, 50vw) - var(--size));
        width: calc(var(--size, 0) * 2);
        height: calc(var(--size, 0) * 2);
        background-color: var(--main-color, var(--labd-theme-color));
        border-radius: var(--size, 100%);
        transform: scale(var(--scale));
        transition: all 0.5s ease-in-out;
        opacity: 1;
    }
}

.full-screen-dialog-content {
    z-index: 1;
    overscroll-behavior: contain;
    overflow: scroll;
    height: calc(var(--vh, 1vh) * 100);
    height: 100dvh;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    padding-top: 3rem;
    @include media-breakpoint-up(md) {
        padding-top: 0;
    }

    @supports (-webkit-touch-callout: none) {
        padding-bottom: 5rem;
    }
}

.windows .full-screen-dialog-content::-webkit-scrollbar {
    display: none;
}

.full-screen-dialog[open] {
    --scroll-x: 0;
    --scroll-y: 0;

    top: 0;
    left: 0;
    display: flex;
    width: 100vw;
    max-width: 100vw;
    height: 100vh;
    min-height: 100vh;
    margin: 0;
    background-color: var(--main-color, var(--labd-theme-color));
    color: var(--main-text-color, black);
    transition: background-color 0.1s 0.5s ease-in;

    &::backdrop {
        visibility: hidden;
    }

    & > * {
        transition: opacity 0.25s 0.5s ease-in, padding-left 0.25s 0.4s ease-in;
        opacity: 1;
    }

    &.closing {
        transition-delay: 0s;

        &::before {
            transition-timing-function: ease-out;
        }

        & > * {
            transition: opacity 0.25s ease-out, padding-left 0.25s ease-out;
        }
    }

    &.opening,
    &.closing {
        background-color: transparent;

        & > button,
        & > .full-screen-dialog-content {
            opacity: 0;
        }

        & > .timeline {
            padding-left: 6vw;
        }

        .full-screen-dialog-opener::before {
            transform: scale(1.1) translateZ(0);
        }
    }
}
