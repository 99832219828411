.interactive-background {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;

    &__svg-container {
        position: absolute;
        stroke: var(--labd-theme-color);
    }

    #leaf {
        top: -50vw;
        left: -35vw;
        width: 150%;
        min-width: 1650px;
        transform-origin: 50% 50vw;

        @media only screen and (max-width: 1100px) {
            left: -385px;
        }

        @media only screen and (max-width: 700px) {
            left: -820px;
        }
    }

    #rings {
        right: -65vw;
        bottom: -75vw;
        width: 200vw;

        @media only screen and (max-width: 1200px) {
            right: -780px;
            bottom: -900px;
            width: 2400px;
        }

        @media only screen and (max-width: 780px) {
            right: calc(-780px + (100vw - 780px) * 0.25);
        }
    }

    #ground {
        bottom: -65vw;
        left: -15vw;
        width: 130vw;
        min-width: 1300px;

        @media only screen and (max-width: 1700px) {
            bottom: calc(-1105px + (1700px - 100vw) * 1.25);
        }

        @media only screen and (max-width: 1000px) {
            bottom: -230px;
        }
    }

    #waves {
        bottom: -40vh;
        left: 0;
        width: 100%;
        height: 140%;
    }

    #rain {
        top: -100vw;
        right: -80vw;
        width: 220%;

        @media only screen and (max-width: 1200px) {
            top: -65vw;
            right: -100vw;
            height: 220%;
        }

        @media only screen and (max-width: 820px) {
            top: -533px;
            right: calc(-820px + (820px - 100vw) * 1.45);
        }
    }
}
