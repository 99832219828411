.teaser-list {
    --grid-gap: 1rem;

    @include media-breakpoint-up(lg) {
        --grid-gap: 4rem;
    }

    &__header {
        position: relative;
        padding-right: 1.5rem;
        margin-top: calc(-1 * var(--font-size-h2) * 0.16);
        // for the overhang-letter downwards shift
        --font-size: var(--font-size-h2);
    }

    &__title {
        width: min-content;
        margin-bottom: 0;
        line-height: 0.8;
        text-transform: lowercase;
        opacity: 1;
    }

    &__content {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: var(--grid-gap);

        & > * {
            position: relative;
            flex-basis: 100%;
            height: clamp(21.5rem, 7.5rem + 40vw, 52.5rem);

            @include media-breakpoint-up(lg) {
                flex-basis: calc(50% - var(--grid-gap) / 2);
            }
        }
    }

    @include media-breakpoint-up(lg) {
        &__header {
            padding-right: 0;
        }
    }
}
