.large-image {
    .image-block {
        max-width: unset;
    }

    img {
        width: 100%;
    }

    @include media-breakpoint-up(lg) {
        padding: $grid-gutter-width;
    }

    @include media-breakpoint-up(xxl) {
        padding: 3.125rem;
    }
}
