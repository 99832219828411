@mixin button-reset {
    display: inline-block;
    padding: 0;
    border: none;
    background-color: transparent;
    color: inherit;
    font: inherit;
    text-align: inherit;
}

@mixin center-content {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin fancy-underline-on-parent-hover($color: currentColor) {
    position: relative;
    white-space: nowrap;

    &::after {
        content: "";
        position: absolute;
        right: 0;
        bottom: -0.25rem;
        left: 0;
        height: 2px;
        background-color: $color;
        transform-origin: left;
        transform: scaleX(0);
        transition-property: transform;
        transition-timing-function: ease-out;
        transition-duration: 125ms;
    }

    .is-active > &,
    :hover > &,
    :focus > & {
        color: inherit;

        &::after {
            transform: scaleX(1);
            transition-duration: 175ms;
        }
    }
}

@mixin fancy-underline-on-hover($color: currentColor) {
    position: relative;
    color: inherit;
    text-decoration: none;
    white-space: nowrap;

    &::after {
        content: "";
        position: absolute;
        right: 0;
        bottom: -0.25rem;
        left: 0;
        height: 2px;
        background-color: $color;
        transform-origin: left;
        transform: scaleX(0);
        transition-property: transform;
        transition-timing-function: ease-out;
        transition-duration: 125ms;
    }

    &:hover,
    &:focus {
        color: inherit;
        text-decoration: none;

        &::after {
            transform: scaleX(1);
            transition-duration: 175ms;
        }
    }
}

@mixin fancy-multi-line-underline-on-hover($color: currentColor) {
    display: inline;
    background-image: linear-gradient($color, $color);
    background-position: 0 100%;
    background-size: 0 2px;
    background-repeat: no-repeat;
    text-decoration: none;
    transition-property: background-size;
    transition-timing-function: ease-out;
    transition-duration: 125ms;

    &:hover,
    &:focus {
        background-size: 100% 2px;
        color: inherit;
        text-decoration: none;
        transition-duration: 175ms;
    }
}

@mixin default-transition-setup($transition-property...) {
    transition-property: $transition-property;
    transition-duration: 125ms;
    transition-timing-function: ease-out;
}

@mixin default-transition-enter {
    transition-duration: 175ms;
}

@mixin default-transition($transition-property...) {
    transition-property: $transition-property;
    transition-duration: 200ms;
    transition-timing-function: ease-out;
}

@mixin list-icon-diamond(
    $size: 0.3125rem,
    $mobileOffset: 0.3125rem,
    $desktopOffset: 0.625rem,
    $color: var(--labd-theme-color)
) {
    position: relative;
    padding-left: 1rem + ($size * 2);

    &::before,
    &::after {
        content: "";
        position: absolute;
        left: 0;
        width: 0;
        height: 0;
        border: $size solid transparent;
    }

    &::before {
        top: $mobileOffset;
        border-bottom-color: $color;
    }

    &::after {
        top: $mobileOffset + ($size * 2);
        border-top-color: $color;
    }

    @include media-breakpoint-up(lg) {
        &::before {
            top: $desktopOffset;
        }

        &::after {
            top: $desktopOffset + ($size * 2);
        }
    }
}

@mixin button-default-style {
    position: relative;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    text-align: left;

    @include media-breakpoint-up(sm) {
        width: auto;
        padding: 24px 40px;
        font-size: 1.25rem;
        border-radius: 36px;
    }
}

@mixin button-with-icons {
    padding-right: 84px;
    transition: padding 300ms ease-in;

    .btn-icon--before {
        right: auto;
        left: 24px;
        margin-right: 10px;
        opacity: 0;
        transform: scaleX(0);
        transform-origin: left center;
    }

    .btn-icon--after {
        right: 24px;
        left: auto;
        margin-left: 10px;
        opacity: 1;
        transform: scaleX(1);
        transform-origin: right center;
    }

    @include media-breakpoint-up(sm) {
        padding-right: 124px;

        .btn-icon--before {
            left: 40px;
        }

        .btn-icon--after {
            right: 40px;
        }

        &:hover {
            padding-right: 40px;
            padding-left: 124px;

            .btn-icon--before {
                opacity: 1;
                transform: scaleX(1);
            }

            .btn-icon--after {
                opacity: 0;
                transform: scaleX(0);
            }
        }
    }
}

@mixin text-button-with-icons {
    position: relative;
    padding: 0;
    padding-left: 30px;
    transition: all 300ms ease-in;
    border-radius: 0;

    .btn-icon--before {
        right: auto;
        left: 0;
        margin-right: 10px;
        opacity: 1;
        transform: scaleX(1);
        transform-origin: left center;
    }

    .btn-icon--after {
        right: 0;
        left: auto;
        margin-left: 10px;
        opacity: 0;
        transform: scaleX(0);
        transform-origin: right center;
    }

    &:hover {
        padding-right: 36px;
        padding-left: 0;

        .btn-icon--before {
            opacity: 0;
            transform: scaleX(0);
        }

        .btn-icon--after {
            opacity: 1;
            transform: scaleX(1);
        }
    }

    @include media-breakpoint-up(sm) {
        padding-left: 36px;
        font-size: 1.25rem;

        .btn-icon--before {
            margin-right: 16px;
        }

        .btn-icon--after {
            margin-left: 16px;
        }
    }
}
