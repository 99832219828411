$link-item-border-color: #ccc;

.link-list {
    padding: 0;
    margin: 35px 0 60px;
    list-style: none;

    li {
        position: relative;
        overflow: hidden;
        padding: 25px 0;
        padding-left: -100px;
        border-bottom: 2px solid $link-item-border-color;

        .icon {
            position: absolute;
            left: -40px;
            width: 40px;
            height: auto;
            margin-top: 6px;
            fill: $black;
            transition: left 400ms ease-in-out;
        }

        a {
            display: block;
            padding-left: 0;
            font-size: clamp(2rem, 2vw, 3rem);
            font-weight: $font-weight-bold;
            letter-spacing: $base-letter-spacing;
            text-decoration: none;
            transition: transform 400ms ease-in-out;
        }

        &:hover a {
            color: var(--labd-theme-color);
        }

        &:first-child {
            border-top: 2px solid $link-item-border-color;
        }
    }

    @include media-breakpoint-up(md) {
        li {
            padding: 50px 0;

            &:hover {
                padding-left: 0;

                a {
                    transform: translateX(50px);
                }

                .icon {
                    left: 0;
                    fill: var(--labd-theme-color);
                }
            }
        }
    }

    @include media-breakpoint-up(xl) {
        li {
            .icon {
                margin-top: clamp(6px, 0.5vw, 20px);
            }
        }
    }
}
