.rich-text {
    margin-bottom: 1rem;

    @include media-breakpoint-up(lg) {
        width: 100%;
        max-width: 64rem;
        margin: 0 auto 1rem;
    }

    b {
        font-weight: bold;
    }

    ul {
        @include list-unstyled;

        & > li {
            @include list-icon-diamond($color: var(--icon-color, var(--labd-theme-color)));

            .theme-background & {
                @include list-icon-diamond($color: $black);
            }

            &:not(:last-of-type) {
                margin-bottom: 0.3rem;
            }
        }
    }

    a {
        @include fancy-multi-line-underline-on-hover;

        font-weight: bold;
        white-space: initial;

        &::before {
            content: "";
            position: relative;
            display: inline-block;
            width: 19px;
            height: 21px;
            margin-right: 0.5rem;
            mask-image: url("/static/labdigital/svgs/icon-arrow-right.svg");
            mask-size: 19px 21px;
            mask-repeat: no-repeat;
            transform: translateY(-7%);
            vertical-align: middle;
            background-color: currentcolor;
        }
    }

    & > :last-child {
        margin-bottom: 0 !important;
    }
}
