.footer-contact {
    display: grid;
    gap: 30px;
    grid-template-columns: 1fr;
    margin-bottom: 64px;

    &__label {
        color: rgba($white, 0.6);
    }

    &__item {
        & > :not(:last-child) {
            margin-bottom: 12px;
        }
    }

    &__item address {
        margin-bottom: 0;
        color: $white;
        font-size: clamp(1.5rem, 1.65vw, 2rem);
    }

    &__link {
        @include fancy-underline-on-hover;

        font-size: clamp(1.5rem, 1.65vw, 2rem);
    }

    @include media-breakpoint-up(md) {
        grid-template-columns: repeat(2, 1fr);
    }

    @include media-breakpoint-up(lg) {
        margin-bottom: 106px;
    }

    @include media-breakpoint-up(xl) {
        grid-template-columns: repeat(4, 1fr);
    }
}

.footer-contact-social__link {
    @include default-transition-setup(color);

    color: inherit;

    .icon {
        fill: currentcolor;
    }

    &:hover,
    &:focus {
        @include default-transition-enter;

        color: $gray-500;
    }
}
