.hero-cta-block {
    --main-color: black;
    --main-text-color: white;
    --accent-color: var(--labd-theme-color);
}

.hero-ctas {
    --size: clamp(8rem, 24vw, 15rem);

    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-end;
    gap: clamp(1rem, 3vw, 2rem);
    margin: var(--bs-gutter-x, 1.5rem);

    & > *:nth-child(2) {
        --delay: 1.75s;

        position: relative;
        margin-bottom: calc(var(--size) * 0.5);
    }

    @include media-breakpoint-up(lg) {
        bottom: 4rem;
    }
}

.hero-cta-button {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
    width: var(--size);
    height: var(--size);
    padding-top: 1rem;
    background-color: var(--main-color);
    color: var(--main-text-color);
    font-size: clamp(1rem, 3vw, 2rem);
    font-weight: 700;
    text-align: center;
    text-decoration: none;
    border-radius: 50%;
    cursor: pointer;

    &:hover {
        color: var(--main-text-color);
    }

    > * {
        position: relative;
    }

    &__icon {
        --size: 20%;

        display: flex;
        flex: 0 0 auto;
        justify-content: center;
        align-items: center;
        width: var(--size);
        height: var(--size);
        border: 2px dashed;
        margin-top: 10px;
        border-radius: 50%;

        svg {
            width: 42%;
            stroke: #000;
            fill: #fff;
            stroke-width: 0.25px;
        }
    }

    & > img {
        bottom: 0.25rem;
        width: 60%;
        height: 60%;
        image-rendering: crisp-edges !important;
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        width: var(--size);
        height: var(--size);
        border-radius: var(--size);
        background-color: var(--main-color);
    }

    &:hover::before {
        animation: inhale 0.5s;
        transform: scale(1.1);
    }
}
