.animation-hero {
    position: relative;
    overflow: hidden;
    width: 100%;

    .interactive-background {
        overflow: initial;
        height: 100vh;
    }

    > *:nth-child(2) {
        height: 100vh;
    }

    &__title {
        --header-shift: calc(var(--header-total-height-mobile) + var(--breadcrumbs-height-mobile));
        $lineHeight: 0.9;
 
        @include media-breakpoint-up(md) {
            --header-shift: calc(var(--header-total-height-tablet) + var(--breadcrumbs-height-tablet));
        }

        @include media-breakpoint-up(xl) {
            --header-shift: calc(var(--header-total-height-desktop) + var(--breadcrumbs-height-desktop));
        }

        position: relative;
        top: var(--header-shift);
        display: flex;
        flex-direction: column;
        justify-content: center;

        @include media-breakpoint-down(md) {
            top: calc(var(--header-shift) / 2);
            height: calc(100vh - var(--header-shift));
            transform: none;
        }

        h1 {
            hyphens: manual;
        }

        &-mask {
            display: block;
            line-height: $lineHeight;
            clip-path: inset(0 0 calc(clamp(80px, 12.5vw, 280px) * ($lineHeight - 1.1)) 0);
        }

        &-line {
            display: block;
            text-transform: lowercase;
            transform: translateY(120%);
        }
    }

    .statement {
        padding-top: 80px;
        padding-bottom: 120px;

        @include media-breakpoint-up(xxl) {
            padding-top: 80px;
            padding-bottom: 240px;
        }
    }

    .hero-cta-button {
        opacity: 0;
        transform: scale(0);
    }

    .interactive-background__svg-container {
        transform: scale(1.2);
    }

    &.intro-animated {
        .hero-cta-button {
            animation: animation-hero-vision-intro 0.75s;
            animation-delay: var(--delay, 1.25s);
            animation-timing-function: ease-out;
            animation-fill-mode: forwards;
        }

        .animation-hero__title {
            $animation-speed: 0.5s;

            &-mask {
                &:nth-child(2) .animation-hero__title-line {
                    animation-delay: $animation-speed - 0.2s;
                }

                &:nth-child(3) .animation-hero__title-line {
                    animation-delay: ($animation-speed - 0.2s) * 2;
                }
            }

            &-line {
                animation: animation-hero-title-intro $animation-speed;
                animation-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
                animation-fill-mode: forwards;
            }
        }

        .interactive-background__svg-container {
            animation: interactive-background-pattern-intro 2.5s;
            animation-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
            animation-fill-mode: forwards;
        }
    }
}

@keyframes animation-hero-title-intro {
    from {
        transform: translateY(120%);
    }

    to {
        transform: translateY(0);
    }
}

@keyframes animation-hero-vision-intro {
    from {
        transform: scale(0);
        opacity: 0;
    }

    to {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes interactive-background-pattern-intro {
    from {
        transform: scale(1.2);
    }

    to {
        transform: scale(1);
    }
}
