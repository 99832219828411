$static-path: "/static/labdigital";

// Typograpy
$base-letter-spacing: -0.05em;

// Block spacings
$block-spacing-mobile: 72px;
$block-spacing-tablet: 120px;
$block-spacing-desktop: 155px;

// block spacings content page
$block-spacing-mobile-contentpage: 40px;
$block-spacing-tablet-contentpage: 72px;
$block-spacing-desktop-contentpage: 80px;

// Header
:root {
    --header-padding-top-mobile: 1.5rem;
    --header-padding-top-tablet: 2rem;
    --header-padding-top-desktop: 4rem;
    --header-height-mobile: 2rem;
    --header-height-tablet: 2rem;
    --header-height-desktop: 3rem;
    --header-padding-bottom-mobile: 1.5rem;
    --header-padding-bottom-tablet: 2rem;
    --header-padding-bottom-desktop: 2rem;
    --header-total-height-mobile: calc(var(--header-height-mobile) + var(--header-padding-bottom-mobile) + var(--header-padding-top-mobile));
    --header-total-height-tablet: calc(var(--header-height-tablet) + var(--header-padding-bottom-tablet) + var(--header-padding-top-tablet));
    --header-total-height-desktop: calc(var(--header-height-desktop) + var(--header-padding-bottom-desktop) + var(--header-padding-top-desktop));
    --breadcrumbs-height-mobile: 55px;
    --breadcrumbs-height-tablet: 88px;
    --breadcrumbs-height-desktop: 88px;
}