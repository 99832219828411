.intro-text {
    @include media-breakpoint-up(lg) {
        width: 100%;
        max-width: 64rem;
        margin: 0 auto;
    }

    p {
        line-height: 1.3;
        letter-spacing: initial;
    }
}
