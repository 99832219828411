.image-block {
    position: relative;
    max-width: 80rem;
    margin: 0 auto;

    &__image {
        width: 100%;
    }

    &.description-open {
        .image-block__toggle {
            transform: rotate(45deg);
        }

        .image-block__caption {
            display: none;
        }

        .image-block__overlay {
            opacity: 1;
        }

        @include media-breakpoint-down(lg) {
            position: fixed;
            z-index: $zindex-fixed;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .image-block__footer {
                top: 0;
                bottom: auto;
                padding-top: 1rem;
                background-image: none;
            }
        }
    }

    &__footer {
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        padding: 1rem;

        &.image-block__gradient {
            padding-top: 4rem;
            background-image: linear-gradient(to top, #00000054, #f000 100%);
        }
    }

    &__caption {
        color: $white;
        font-size: 0.875rem;
        text-align: right;
    }

    &__toggle {
        @include center-content;

        z-index: 11;
        flex: 0 0 auto;
        width: 40px;
        height: 40px;
        margin-left: 0.5rem;
        background-color: var(--labd-theme-color);
        border-radius: 50%;
        transition: all 200ms ease-in;

        .icon {
            width: 18px;
            height: 18px;
        }

        &:hover {
            background-color: black;
            color: white;
        }
    }

    &__description {
        color: $white;
    }

    &__overlay {
        position: absolute;
        z-index: 10;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        padding: 75px 24px;
        background-color: rgba($black, 0.8);
        opacity: 0;
        transition: opacity 200ms ease-in-out;
    }

    @include media-breakpoint-up(lg) {
        &__caption {
            font-size: 1.125rem;
        }

        &__footer {
            padding: 1.75rem;
        }

        &__toggle {
            width: 56px;
            height: 56px;
            margin-left: 1rem;

            .icon {
                width: 24px;
                height: 24px;
            }
        }

        &__overlay {
            padding: 72px;
        }
    }
}
