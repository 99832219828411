.overhang-container {
    position: relative;

    & > * {
        position: relative;
    }
}

.overhang-text {
    position: relative;
    top: calc(var(--font-size) * 0.16);

    & > span:last-child {
        position: relative;
        z-index: 1;
    }
}

.overhang-clone {
    position: absolute;
    z-index: +1;
    top: calc(var(--font-size) * 0.16);
    width: fit-content;
    user-select: none;

    & > .overhang-clone-text {
        opacity: 0;
    }

    & > .overhang-clone-letter {
        position: absolute;
        right: 0;
        bottom: 0;
    }
}
