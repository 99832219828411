.usp-list {
    margin-bottom: 28px;

    &__title {
        margin-bottom: 20px;
    }

    &__list {
        @include list-unstyled;

        margin-bottom: 0;
    }

    &__item {
        @include list-icon-diamond;

        &:not(:last-of-type) {
            margin-bottom: 0.3rem;
        }
    }

    @include media-breakpoint-up(lg) {
        margin-bottom: 0;

        &__title {
            margin-bottom: 36px;
        }
    }
}
