@mixin black-card {
    --main-color: black;
    --main-text-color: white;
    --accent-color: var(--labd-theme-color);
    --accent-text-color: black;
}

@mixin colored-card {
    --main-color: var(--labd-theme-color);
    --main-text-color: black;
    --accent-color: black;
    --accent-text-color: white;
}

@mixin gray-card {
    --main-color: #ccc;
    --main-text-color: black;
    --accent-color: black;
    --accent-text-color: white;
}

@mixin card-grid {
    > *:nth-child(3n + 1) {
        @include black-card;
    }

    > *:nth-child(3n + 2) {
        @include colored-card;
    }

    > *:nth-child(3n + 3) {
        @include gray-card;
    }

    @include media-breakpoint-up(lg) {
        > *:nth-child(9n + 0),
        > *:nth-child(9n + 1),
        > *:nth-child(9n + 5) {
            @include black-card;
        }

        > *:nth-child(9n + 2),
        > *:nth-child(9n + 6),
        > *:nth-child(9n + 7) {
            @include colored-card;
        }

        > *:nth-child(9n + 3),
        > *:nth-child(9n + 4),
        > *:nth-child(9n + 8) {
            @include gray-card;
        }
    }

    > * {
        flex: 0 0 100%;
        background-color: var(--main-color);
        color: var(--main-text-color);

        .badge {
            background-color: var(--accent-color);
            color: var(--accent-text-color);
        }

        a,
        a:hover {
            color: var(--main-text-color);
        }

        .btn-primary,
        .btn-primary:hover {
            background-color: var(--main-text-color);
            color: var(--accent-text-color);
        }

        .btn-tertiary,
        .btn-tertiary:hover {
            color: var(--main-text-color);
        }
    }

    @include media-breakpoint-up(md) {
        > * {
            // the width must keep the gap in consideration
            // 1 gaps of --grid-gap rem divided over 3 items =
            // 1 * --grid-gap / 2 = 2rem
            flex: 0 1 calc(50% - 1 * var(--grid-gap) / 2);
            max-width: calc(50% - 1 * var(--grid-gap) / 2);
        }
    }

    @include media-breakpoint-up(lg) {
        > * {
            // the width must keep the gap in consideration
            // 2 gaps of --grid-gap rem divided over 3 items =
            // 2 * --grid-gap / 3 = 2rem
            flex: 0 1 calc(33.33% - 2 * var(--grid-gap) / 3);
            max-width: calc(33.33% - 2 * var(--grid-gap) / 3);
        }
    }
}
